import React from 'react'
import Loader from '../../containers/loader/loader'

interface IQuizAnswerProps {
  result: any
  onClick: any
  loading: boolean
}
const QuizAnswer: React.FC<IQuizAnswerProps> = (props: any) => {
  const { result = {}, onClick, loading = false } = props
  const { selectedAnswer, rightAnswer } = result
  let title = 'Hooray!',
    des = 'You pick the right answer, keep it up!',
    icon = '/images/icons/icon-finished.jpg'
  if (selectedAnswer != rightAnswer) {
    title = 'Nope!'
    des = 'You picked the wrong answer, better luck next time!'
    icon = '/images/icons/smiley/bad.png'
  }
  return (
    <div className='w-row section-quiz-result'>
      <div className='w-col-12 icon-finished'>
        <img src={icon} alt='' />
      </div>
      <div id='session_Head' className='w-col-12 session-title'>
        <span>{title}</span>
      </div>
      <div className='w-col-12 story-des m-al-c'>
        <p>{des}</p>

        {(loading && <Loader showText={false} />) || (
          <span className='m-link m-b color-primary w-inline-block' onClick={onClick}>
            View result
          </span>
        )}
      </div>
    </div>
  )
}

export default QuizAnswer
