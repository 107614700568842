import React from 'react'
import Word from './Word'

interface IQuizQuestionProps {
  questionDetail?: any
  showPinYin: any
  handleClickWord?: any
  activeIndex?: any
  story: any
  chineseWordFontSize?: any
}

const QuizQuestion: React.FC<IQuizQuestionProps> = (props: any) => {
  const { questionDetail, showPinYin, story, activeIndex, handleClickWord, chineseWordFontSize } = props

  return (
    <>
      {questionDetail?.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
        return (
          <React.Fragment key={index}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`Q${index}`}
                isActive={`Q${index}` === activeIndex}
                text={stripedWord}
                isNewWord={stripedWord === story?.newWord1 || stripedWord === story?.newWord2}
                isOldWord={isOldWord}
                onClick={handleClickWord}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
                chineseWordFontSize={chineseWordFontSize}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
      {/* <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} /> */}
    </>
  )
}

export default QuizQuestion
