import React, { useEffect, useRef, useState } from 'react'
import { characterConst, converterType, wordType } from '../../shared/constant'
import Translate from '../popup/Translate'
import { translate } from '../../redux/actions/storyAction'
import LeftStory from './LeftStory'
import NewWord from './NewWord'
import Word from './Word'
import axios from 'axios'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'

interface IStoryProps {
  story?: any
  showPinYin?: boolean
  user_auth_token: any
  skritter_token: any
}

const { REACT_APP_HACK_CHINESE_APP_KEY } = process.env

const Story: React.FC<IStoryProps> = (props: any) => {
  const { story, showPinYin, user_auth_token } = props
  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const popupRef = useRef<HTMLInputElement>(null)
  const newWord1 =
    globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(props.story?.newWord1)
      : converterType.converterSimplified(props.story?.newWord1)
  const newWord2 =
    globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(props.story?.newWord2)
      : converterType.converterSimplified(props.story?.newWord2)

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.story, newWord1, newWord2])

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const isOldWord = story?.oldWords?.includes(word)
    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }

    const wordTranslate = props.story.arContent.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )

    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }
  return (
    <>
      <LeftStory story={story} handleClickWord={handleClickWord} activeIndex={activeIndex} showPinYin={showPinYin} />
      <NewWord story={story} handleClickWord={handleClickWord} activeIndex={activeIndex} />
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </>
  )
}
export { Word }
export default Story
