import axios from 'axios'
import jwt from 'jsonwebtoken'

const BASE_URL = 'https://api.videosdk.live/v2'
const API_KEY = process.env.REACT_APP_VIDEO_SDK_API_KEY
const SECRET = process.env.REACT_APP_VIDEO_SDK_API_SECRET

export const generateSDKToken = () => {
  const options = {
    expiresIn: '10m',
    algorithm: 'HS256',
  }
  const payload = {
    apikey: API_KEY,
    version: 2,
    roles: ['CRAWLER'],
  }
  const token = jwt.sign(payload, SECRET, options)
  // console.log("generateSDKToken", token);
  return token
}

export const createRoom = async (customRoomId: string) => {
  try {
    const tempForm = { customRoomId: customRoomId }
    const headers = {
      Authorization: generateSDKToken(),
      'Content-Type': 'application/json',
    }
    const res = await axios.post(`${BASE_URL}/rooms`, tempForm, {
      headers: headers,
    })
    return res
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}

export const validateRoom = async (roomId: string) => {
  try {
    const headers = {
      Authorization: generateSDKToken(),
      'Content-Type': 'application/json',
    }
    const res = await axios.get(`${BASE_URL}/rooms/validate/${roomId}`, {
      headers: headers,
    })
    return res
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}

export const endSession = async (roomId: string) => {
  try {
    const tempForm = { roomId: roomId }
    const headers = {
      Authorization: generateSDKToken(),
      'Content-Type': 'application/json',
    }
    const res = await axios.post(`${BASE_URL}/sessions/end`, tempForm, {
      headers: headers,
    })
    return res
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}
