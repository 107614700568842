import React, { useEffect, useRef } from 'react'
import { offsetTrans, wordType } from '../../shared/constant'
import Parser from 'html-react-parser'
import { extractDefinition } from '../../utils/utils'
declare let $: any
interface ITranslateProps {
  visible?: boolean
  data?: any
  onClose(): any
}
const Translate = React.forwardRef<HTMLInputElement, ITranslateProps>((props: any, ref: any) => {
  const popupRef = useRef(document.createElement('div'))
  const popupHeaderRef = useRef(document.createElement('div'))
  const { data = {} } = props
  const { type = wordType.Word } = data

  useEffect(() => {
    const posX = window.screen.width - data.left >= 200 ? data.left : 200 - (window.screen.width - data.left)
    let posY = data.top + offsetTrans
    if (popupRef.current.clientHeight > 0) {
      if (posY + popupRef.current.clientHeight > window.screen.height) {
        posY -= popupRef.current.clientHeight + offsetTrans
      }
    } else {
      posY += offsetTrans
    }
    if (data.top > 0 && popupRef.current) {
      popupRef.current.style.top = `${posY}px`
    }
    if (data.left > 0 && popupRef.current) {
      popupRef.current.style.left = `${posX}px`
    }
    if (props.visible && popupRef.current) {
      popupRef.current.style.display = 'block'
    } else {
      popupRef.current.style.display = 'none'
    }
  }, [props])

  useEffect(() => {
    //show newword
    if ((type === wordType.NewWord || type === wordType.OldWord) && popupHeaderRef.current) {
      popupHeaderRef.current.style.display = 'block'
      $('.close-popup-less').hide()
    } else {
      popupHeaderRef.current.style.display = 'none'
      $('.close-popup-less').show()
    }
  }, [popupHeaderRef, type])
  const onClose = () => {
    props.onClose()
  }
  return (
    <div ref={ref}>
      <div className='popup' ref={popupRef}>
        <div className={`popup-header ${type === wordType.OldWord ? 'old-word' : ''}`} ref={popupHeaderRef}>
          <p>{type === wordType.NewWord ? `New Words` : `Words From The Past`}</p>
          <button onClick={onClose}>
            <i className='fa fa-times' aria-hidden='true'></i>
          </button>
        </div>
        <div className='popup-body'>
          <button className='close-popup-less' onClick={onClose}>
            <i className='fa fa-times' aria-hidden='true'></i>
          </button>
          <div className='translate-content'>
            <div className='translate-word'>{data?.word}</div>
            <p className='translate-definition'>{data?.definition?.toLowerCase() || ''}</p>
          </div>
          <div className='translate-example'>{Parser(extractDefinition(data?.example1))}</div>
        </div>
        {/* <div className="popup-footer">
          <div className="content-footer">
            <p className="translate-level">{data?.level}</p>
          </div>
        </div> */}
      </div>
    </div>
  )
})

export default Translate
