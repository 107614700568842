import AWS from 'aws-sdk'
const { REACT_APP_IDENTITY_POOL_ID, REACT_APP_S3_REGION_AWS, REACT_APP_S3_BUCKET } = process.env

AWS.config.update({
  region: REACT_APP_S3_REGION_AWS,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: REACT_APP_IDENTITY_POOL_ID ? REACT_APP_IDENTITY_POOL_ID.toString() : '',
  }),
})

const s3 = new AWS.S3()

export const uploadFileOnS3 = ({ uploadKey, file, contentType }) => {
  return s3
    .upload({
      Bucket: REACT_APP_S3_BUCKET ? REACT_APP_S3_BUCKET.toString() : '',
      Key: uploadKey,
      ContentType: contentType,
      Body: file,
    })
    .promise()
}

export const getSignedUrl = ({ key }) => {
  const params = {
    Bucket: REACT_APP_S3_BUCKET ? REACT_APP_S3_BUCKET.toString() : '',
    Key: key,
    //  Expires: 900, // In seconds
  }
  return s3.getSignedUrlPromise('getObject', params)
}
