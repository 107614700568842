import moment from 'moment-timezone'
import { membershipNameType, membershipType, SUNDAY } from '../shared/constant'

// import * as crypto from 'crypto-js'

export function toUpperFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function momentToMilis(mnt: any) {
  return (mnt.get('h') * 60 * 60 + mnt.get('m') * 60 + mnt.get('s')) * 1000
}

/* function unescapeHTML(htmlString: string) {
  const escapeEl = document.createElement('textarea')
  escapeEl.innerHTML = htmlString
  return escapeEl.textContent
} */

export function extractDefinition(storyContent: string) {
  if (storyContent) {
    const arSentences = storyContent.split('/')
    const filtered = arSentences.filter((arSentence) => !arSentence.includes('CL:'))
    if (filtered && filtered.length > 0) {
      if (filtered.length > 3) {
        filtered.splice(3, filtered.length - 3)
      }
      return `<span>${filtered.join('</span><span>')}</span>`
    }
    return `<span>${storyContent}</span>`
  }
  return ''
}

export function convertAudioTime(time) {
  if (!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
  }
}

export const idValidAccount = (userMemberShipTypeName = '', memberShipStatus) => {
  if (!userMemberShipTypeName && !memberShipStatus) return false
  return (
    userMemberShipTypeName.toLowerCase() === membershipNameType.School.toLowerCase() ||
    userMemberShipTypeName.toLowerCase() === membershipNameType.Premium.toLowerCase() ||
    userMemberShipTypeName.toLowerCase() === membershipNameType.Standard.toLowerCase() ||
    userMemberShipTypeName.toLowerCase() === membershipNameType.Pro.toLowerCase() ||
    userMemberShipTypeName.toLowerCase() === membershipNameType.Teacher.toLowerCase() ||
    memberShipStatus.toLowerCase() === membershipType.Paid.toLowerCase()
  )
}

export const isSunday = () => {
  return moment.utc().format('ddd') === SUNDAY
}

// export function encryptData(data: string, publicKey: string): string {
//   try {
//     const encryptedData = crypto.publicEncrypt(
//       {
//         key: publicKey,
//         padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
//         oaepHash: 'sha256',
//       },
//       Buffer.from(data),
//     )
//     // console.log(encryptedData.toString('base64'))
//     return encryptedData.toString('base64')
//   } catch (error) {
//     console.log({ error })
//     return ''
//   }
// }

export const parseQuery = (queryString) => {
  if (!queryString) return ''
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export const epochTimeDifferenceInText = (time) => {
  const currentDate = moment()
  const pastDate = moment.unix(parseInt(time) / 1000)
  const diffYears = currentDate.diff(pastDate, 'year')
  const diffMonths = currentDate.diff(pastDate, 'months')
  const diffDays = currentDate.diff(pastDate, 'days')
  const diffHours = currentDate.diff(pastDate, 'hours')
  const diffMinutes = currentDate.diff(pastDate, 'minutes')
  const difference = diffYears
    ? `${diffYears} years`
    : diffMonths
    ? `${diffMonths} months`
    : diffDays
    ? `${diffDays} days`
    : diffHours
    ? `${diffHours} hours`
    : `${diffMinutes} minutes`
  return difference
}

export const answerValidate = async (answer) => {
  if (answer.length < 5) {
    //setAnswerError('Your answer is too short!')
    return { error: 'Your answer is too short!' }
  }

  //const letterRegex = /^[。!@#"'%&:;,.-=_<>\+\d\s\n\p{Script=Hani}]*$/gu
  const letterRegex = /^[^a-zA-Z]*$/gu
  const validatedAnswer = letterRegex.test(answer)
  if (validatedAnswer) {
    // setAnswerError('')
    return { error: '' }
  } else {
    // setAnswerError()
    return { error: 'The answer should be in Chinese!' }
  }
}

export const getBlobDuration = async (blob): Promise<number> => {
  const tempAudioEl = new Audio(blob)
  tempAudioEl.preload = 'metadata'
  tempAudioEl.muted = true

  const durationP: Promise<number> = new Promise((resolve, reject) => {
    tempAudioEl.addEventListener('canplay', async () => {
      tempAudioEl.play()
    })
    tempAudioEl.addEventListener('loadedmetadata', () => {
      if (tempAudioEl.duration === Infinity) {
        // When Infinity Duration
        tempAudioEl.currentTime = Number.MAX_SAFE_INTEGER
        tempAudioEl.ontimeupdate = () => {
          if (tempAudioEl.duration !== Infinity) {
            tempAudioEl.ontimeupdate = () => {
              return
            }
            tempAudioEl.currentTime = 0
            tempAudioEl.pause()
            resolve(tempAudioEl.duration)
          }
        }
      } else resolve(tempAudioEl.duration) // Normal behavior
    })
    tempAudioEl.onerror = (event) => reject(event)
  })

  return durationP
}
