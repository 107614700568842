import { SET_GENERAL_RESULT, SET_QUIZ, RESET_QUIZ, SET_SUBMIT_QUIZ } from '../types'

interface IQuizState {
  quizDetail: any
  quizAnswer: any
  quizResult: any
}

const initialState: IQuizState = {
  quizDetail: null,
  quizAnswer: null,
  quizResult: null,
}

export default function quizReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_QUIZ:
      return {
        ...state,
        quizDetail: {
          ...action.payload,
        },
        quizAnswer: (action.payload.memberAnswer && { ...action.payload.memberAnswer }) || null,
        isExistsAnswer: !!action.payload.memberAnswer,
      }
    case RESET_QUIZ:
      return {
        ...state,
        quizDetail: null,
        quizAnswer: null,
        quizResult: null,
      }
    case SET_SUBMIT_QUIZ:
      return {
        ...state,
        quizAnswer: {
          ...action.payload,
        },
      }
    case SET_GENERAL_RESULT:
      return {
        ...state,
        quizResult: {
          ...action.payload,
        },
      }
    default:
      return state
  }
}
