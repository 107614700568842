import React, { useMemo, useRef, useState } from 'react'
import FormInput from './FormInput'

interface IPasswordInputProps {
  memberId?: string
  email?: string
  savePassword?: any
  onChangePassword?: any
  onPasswordSuccess?: any
  onPasswordError?: any
}
const PasswordInput: React.FC<IPasswordInputProps> = (props: any) => {
  const { memberId, email, savePassword, onChangePassword, onPasswordSuccess, onPasswordError } = props

  const rePwRef = useRef<HTMLInputElement>(null)
  const [isEdit, setEdit] = useState(false)
  const [currentPw, setCurrentPw] = useState('***********')
  const [pwSubmitting, setPwSubmitting] = useState(false)
  const [newPw, setNewPw] = useState('')
  const [rePw, setRePw] = useState('')
  const changeToEdit = (editMode) => {
    setCurrentPw(editMode ? '' : '************')
    setEdit(editMode)
  }
  const onCurrentPwChange = (e) => {
    setCurrentPw(e?.target?.value)
    onChangePassword()
  }
  const onNewPwChange = (e) => {
    setNewPw(e?.target?.value)
    onChangePassword()
  }
  const onRePwChange = (e) => {
    setRePw(e?.target?.value)
    onChangePassword()
  }

  const onUpdatePassword = () => {
    if (!pwSubmitting) {
      setPwSubmitting(true)
      if (newPw !== rePw) {
        setPwSubmitting(false)
        onPasswordError('Password does not match')
        rePwRef?.current?.focus()
      } else {
        savePassword(currentPw, newPw, rePw, email)
          .then((res) => {
            onPasswordSuccess()
            setPwSubmitting(false)
            changeToEdit(false)
          })
          .catch((e) => {
            onPasswordError(e)
            setPwSubmitting(false)
            changeToEdit(true)
          })
      }
    }
  }
  return (
    <>
      <FormInput
        label={isEdit ? 'Enter Current Password' : 'Password'}
        type={'password'}
        value={currentPw}
        showButton={!isEdit}
        isEdit={isEdit}
        onEditMode={changeToEdit}
        onChange={onCurrentPwChange}
      />
      {isEdit ? (
        <>
          <FormInput
            label={'Enter New Password'}
            type={'password'}
            isEdit={true}
            showButton={false}
            onChange={onNewPwChange}
          />
          <FormInput
            label={'Repeat Password'}
            type={'password'}
            isEdit={true}
            showButton={!!(currentPw && newPw && rePw)}
            onEditMode={changeToEdit}
            onChange={onRePwChange}
            onSave={onUpdatePassword}
            ref={rePwRef}
            isSubmitting={pwSubmitting}
          />
        </>
      ) : null}
    </>
  )
}
export default PasswordInput
