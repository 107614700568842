import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useDispatch, useSelector } from 'react-redux'
import { noKnownWord } from 'redux/actions/onboardingAction'
import { SET_IS_RESULT, SET_WORDSLIST } from 'redux/types'
import { useLocation } from 'react-router-dom'
import TooltipWord from 'containers/tooltip-word/TooltipWord'
import { characterConst, converterType, languageList } from 'shared/constant'

const VocabularyTest = ({ nextStep }) => {
  const { wordDetail, tempUser, preferenceType, languageInfo }: any = useSelector<any>(({ onboarding }) => onboarding)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [transData, setTransData]: any[] = useState([])

  const handle = {
    onWordClick: async (word) => {
      const newWordList = [...wordDetail?.wordList]
      const response = await noKnownWord({ word, temp_user: tempUser })
      const wordIndex = wordDetail?.wordList?.findIndex((wordInfo) => wordInfo._id === word?._id)
      if (response?.data?.newWordList) {
        newWordList.splice(wordIndex, 1, response?.data?.newWordList)
        dispatch({ type: SET_WORDSLIST, payload: newWordList })
      } else {
        const disableWord = { ...word, is_disable: true }
        newWordList.splice(wordIndex, 1, disableWord)
        dispatch({ type: SET_WORDSLIST, payload: newWordList })
      }
    },
    navigate: async (val: boolean) => {
      dispatch({ type: SET_IS_RESULT, payload: val })
      pathname.includes('/existing') ? nextStep(7) : nextStep(4)
    },
  }

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    if (wordDetail?.wordList?.length > 0) {
      const transData1 = wordDetail?.wordList?.map((wordInfo) => {
        let trsnWord = wordInfo.word

        if (preferenceType) {
          trsnWord =
            preferenceType === characterConst.traditional
              ? converterType.converterTraditional(wordInfo.word)
              : converterType.converterSimplified(wordInfo.word)
        }
        return {
          ...wordInfo,
          wrd: wordInfo.word,
          word: trsnWord,
          isVisible: !transData.length ? false : true,
        }
      })

      setTransData(transData1)
    }
  }, [wordDetail])

  return (
    <>
      <div className='w-container'>
        <div className='choose-lunguage-main-div overflow-hidden-mob'>
          <div className='common-title-sec'>
            <h2 className='screen-sub-title'>Tap words you do not know or are unsure about</h2>
            <p className='screen-para-text'>This will help us establish your baseline vocabulary.</p>
          </div>
          <div className='tabs-word-main-div'>
            {transData?.map((wordInfo) => (
              <div key={wordInfo?._id}>
                <TooltipWord
                  text='This word is not clickable because it is the last one from its set.'
                  disabled={!wordInfo?.is_disable}
                >
                  <button
                    disabled={wordInfo?.is_disable}
                    onClick={() => handle.onWordClick(wordInfo)}
                    className={`method-btn hover-hide tabs-word-btn btn outline-btn-class ${
                      languageInfo?.language === languageList.french && 'DM-Sans-font'
                    } `}
                    data-aos='fade-In'
                    data-aos-duration='1500'
                    data-aos-easing='linear'
                    data-aos-once='true'
                  >
                    {wordInfo?.word}
                  </button>
                </TooltipWord>
              </div>
            ))}
          </div>

          <div className='text-center'>
            <button
              className='common-btn-cls green-btn-class btn'
              onClick={() => handle.navigate(true)}
              data-aos='fade-In'
              data-aos-duration='1700'
              data-aos-easing='linear'
              data-aos-once='true'
              data-aos-offset='0'
            >
              I know all remaining words
            </button>
            <p
              className='links-text'
              style={{ textDecoration: 'underline', fontWeight: '700' }}
              onClick={() => handle.navigate(false)}
              data-aos='fade-In'
              data-aos-duration='2000'
              data-aos-easing='linear'
              data-aos-once='true'
            >
              Skip if you never learned the language
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default VocabularyTest
