import * as React from 'react'
import { Word } from '../../containers/story/LeftStory'

import { characterConst, converterType, wordType } from '../../shared/constant'

interface IStoryProps {
  story: any
  handleClickWord: any
  activeIndex: any
  user_auth_token: any
  chineseWordFontSize: any
}
const Story: React.FC<IStoryProps> = (props: any) => {
  const { story, activeIndex, handleClickWord, user_auth_token, chineseWordFontSize } = props

  const { newWord1, newWord2, oldWords } = props.story || {}

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <>
          {story?.storyContent?.map((wordObj, index) => {
            const isOldWord = story?.oldWords?.includes(wordObj.word)
            const isNewWord = story?.newWord1 === wordObj.word || story?.newWord2 === wordObj.word
            const isIncludePTag =
              wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
            const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
            return (
              <React.Fragment key={index}>
                {isIncludePTag && index ? <br /> : ''}
                {stripedWord.length ? (
                  <>
                    <Word
                      key={index}
                      index={index}
                      text={stripedWord}
                      isNewWord={isNewWord}
                      isOldWord={!isNewWord && isOldWord}
                      onClick={(e) => {
                        handleClickWord(e, 0)
                      }}
                      pinYinText={story.showPinYin ? wordObj?.pinyin : null}
                      chineseWordFontSize={chineseWordFontSize?.newWordsExample}
                    />
                  </>
                ) : (
                  ''
                )}
              </React.Fragment>
            )
          })}
        </>
      </div>
    </div>
  )
}

export default Story
