import { CLEAR_QUESTION_ANSWER_LIST, SET_QUESTION, SET_QUESTION_ANSWER_LIST, SET_SUBMIT_QUESTION } from '../types'

interface IQuestionState {
  answers: any
  curAnswer: string
  question: any
  questionContent: any
  isExistsAnswer: boolean
  cursorRefId: any
  cursorTs: any
}
const initialState: IQuestionState = {
  answers: [],
  curAnswer: '',
  question: '',
  questionContent: [],
  isExistsAnswer: false,
  cursorRefId: null,
  cursorTs: null,
}

export default function questionReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_QUESTION_ANSWER_LIST:
      return {
        ...state,
        answers: [...action.payload.answers],
        cursorRefId: action.payload.ref || null,
        cursorTs: action.payload.ts || null,
      }
    case SET_QUESTION:
      return {
        ...state,
        question: action.payload.question,
        questionContent: action.payload.questionContent,
        curAnswer: action.payload.memberAnswer || '',
      }
    case CLEAR_QUESTION_ANSWER_LIST:
      return {
        ...state,
        answers: [],
        cursorRefId: null,
        cursorTs: null,
      }
    case SET_SUBMIT_QUESTION:
      return {
        ...state,
        curAnswer: action.payload,
      }
    default:
      return state
  }
}
