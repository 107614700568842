import React from 'react'

import { Word } from 'containers/story'

interface ICorrectionAnswerProps {
  answerDetail?: any
  showPinYin: any
  handleClickWord: any
  activeIndex: any
  iterator: any
  story: any
  chineseWordFontSize: any
}

const CorrectionAnswer: React.FC<ICorrectionAnswerProps> = (props: any) => {
  const { answerDetail, showPinYin, handleClickWord, activeIndex, iterator, story, chineseWordFontSize } = props

  return (
    <>
      {answerDetail.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isNewWord = story?.newWord1 === wordObj.word || story?.newWord2 === wordObj.word
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')

        return (
          <React.Fragment key={`${iterator}${index}`}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`${iterator}${index}`}
                isActive={`${iterator}${index}` === activeIndex}
                text={stripedWord}
                isNewWord={isNewWord}
                isOldWord={!isNewWord && isOldWord}
                onClick={(e) => {
                  handleClickWord(e, iterator)
                }}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
                chineseWordFontSize={chineseWordFontSize?.newWordsExample}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default React.memo(CorrectionAnswer)
