import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import history from '../../browserHistory'
import LearnWith from './LearnWith'
import WritingMethod from './WritingMethod'
import VocabularyTest from './VocabularyTest'
import WordResult from './WordResult'
import { CHOOSE_LANGUAGE } from 'paths'
import { useDispatch, useSelector } from 'react-redux'
import {
  IS_REFUSER,
  SET_ALL_LANGUAGE,
  SET_ALL_WORDS,
  SET_LANGUAGE_INFO,
  SET_LEARN_ABOUT_ANSWER,
  SET_LEARN_ABOUT_TITLE,
  SET_LEARN_ABOUT_TYPE,
  SET_STEPPER_STEP,
} from 'redux/types'
import SignUp from './SignUp'
import { getAllLanguage, getWordsList } from 'redux/actions/onboardingAction'
import Tooltip from 'containers/tooltip/Tooltip'
import HowDidYouHear from 'components/onboarding/how-did-you-hear'
import SurveyForm from 'components/onboarding/survey-form'

export const renderStepIcon = (step, stepNumber, onClickStep, title) => {
  return (
    <Tooltip text={title} disabled={stepNumber >= step}>
      <div
        className={`step-indicator-item ${step === stepNumber ? 'active' : ''}`}
        onClick={() => onClickStep(stepNumber)}
      ></div>
    </Tooltip>
  )
}

const OnBoarding = () => {
  const { pathname } = useLocation()
  const { id }: any = useParams()
  const dispatch = useDispatch()
  const isRefUser = pathname.split('/')[2] === 'lead'
  const userState: any = useSelector<any>(({ user }) => user)
  const { languageInfo, stepper_step, languages }: any = useSelector<any>(({ onboarding }) => onboarding)

  useEffect(() => {
    if (pathname.includes('/existing') && !userState?.authenticated) {
      history.push('/login')
      return
    }
    if (stepper_step === 7) {
      nextStep(7)
      return
    }
    if ((pathname.includes('/existing') || pathname.includes('/lead')) && languageInfo?.preferences?.length) {
      nextStep(2)
      return
    } else if (pathname.includes('/new')) {
      nextStep(1)
      return
    } else {
      nextStep(3)
      return
    }
  }, [pathname, languageInfo])

  const nextStep = (val) => {
    dispatch({ type: SET_STEPPER_STEP, payload: val })
    if (val === 1) {
      dispatch({ type: SET_LEARN_ABOUT_TYPE, payload: '' })
      dispatch({ type: SET_LEARN_ABOUT_TITLE, payload: '' })
      dispatch({ type: SET_LEARN_ABOUT_ANSWER, payload: '' })
    }
  }

  useEffect(() => {
    handle.pageLoad()
  }, [pathname])

  useEffect(() => {
    dispatch({ type: IS_REFUSER, payload: isRefUser })

    if (stepper_step !== 7 && (isRefUser || userState?.authenticated)) {
      history.push(`/${pathname.split('/')[1]}/${userState?.authenticated ? 'existing' : isRefUser ? 'lead' : 'new'}`)
    }
    if (userState?.authenticated) {
      dispatch({ type: IS_REFUSER, payload: false })
    }
  }, [isRefUser])

  const handle = {
    pageLoad: async () => {
      if (stepper_step !== 7 && pathname.includes('/new')) {
        nextStep(1)
      }
      if (stepper_step !== 7 && languages?.length <= 0) {
        const newLan = id.split('-')[1]
        const newLanguage = newLan.charAt(0).toUpperCase() + newLan.slice(1)
        const response = await getAllLanguage()
        dispatch({ type: SET_ALL_LANGUAGE, payload: response?.data })

        if (newLanguage) {
          const newLanguageInfo = response?.data?.find((languageInfo) => languageInfo?.language === newLanguage)

          const newPreferences = newLanguageInfo?.preferences?.map((preference, i) => ({
            ...preference,
            is_active: i === 0 ? true : false,
          }))

          dispatch({ type: SET_LANGUAGE_INFO, payload: { ...newLanguageInfo, preferences: newPreferences } })

          if (newLanguageInfo && !newLanguageInfo?.preferences?.length) {
            const response = await getWordsList({ language_id: newLanguageInfo?._id })
            dispatch({ type: SET_ALL_WORDS, payload: { wordList: response?.data } })
          }
        }
      }
    },
    onClickStep: (stepNum) => {
      if (stepNum > stepper_step) {
      } else {
        nextStep(stepNum)
      }
    },
  }

  return (
    <div className='container mob-lr-padding'>
      <div className={stepper_step !== 7 ? 'common-stapper-padding' : ''}>
        {stepper_step === 7 ? null : (
          <div className='stapper-main-div'>
            {stepper_step === 1 && (
              <img
                className='back-arrow-img'
                alt={'icon'}
                src={'/images/arrow-back.png'}
                onClick={() => history.push('/')}
              />
            )}
            <div className='stapper-circle-div'>
              {pathname.includes('/existing') || pathname.includes('/lead')
                ? null
                : renderStepIcon(stepper_step, 1, handle.onClickStep, 'Methodology')}
              {!languageInfo?.preferences?.length
                ? null
                : renderStepIcon(stepper_step, 2, handle.onClickStep, 'Writing Method')}
              {renderStepIcon(stepper_step, 3, handle.onClickStep, 'Words Test')}
              {pathname.includes('/existing') ? null : (
                <>
                  {renderStepIcon(stepper_step, 4, handle.onClickStep, 'Survey')}
                  {renderStepIcon(stepper_step, 5, handle.onClickStep, 'Survey')}
                </>
              )}
              {pathname.includes('/existing')
                ? null
                : renderStepIcon(stepper_step, 6, handle.onClickStep, 'Register Page')}
            </div>
          </div>
        )}
        {stepper_step === 1 && <LearnWith nextStep={nextStep} />}
        {stepper_step === 2 && <WritingMethod nextStep={nextStep} />}
        {stepper_step === 3 && <VocabularyTest nextStep={nextStep} />}
        {pathname.includes('/existing') ? null : (
          <>
            {stepper_step === 4 && <HowDidYouHear nextStep={nextStep} />}
            {stepper_step === 5 && <SurveyForm nextStep={nextStep} />}
          </>
        )}
        {stepper_step === 6 && <SignUp />}
        {stepper_step === 7 && <WordResult nextStep={nextStep} />}
      </div>
    </div>
  )
}

export default OnBoarding
