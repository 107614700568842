import React, { useEffect } from 'react'
import { VideoSDKMeeting } from '@videosdk.live/rtc-js-prebuilt'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { validateRoom } from 'services/meet'
import history from '../../browserHistory'
import { ACCOUNT, DASHBOARD } from 'paths'
import { membershipNameType } from 'shared/constant'

type Params = { id: string }

const Meet: React.FC = (props: any) => {
  const param = useParams<Params>()
  const { id } = param
  const meetingId = id
  const user = useSelector((state) => state['user'])
  const membership = useSelector((state) => state?.['user']?.membership)
  const name = user?.credentials?.customFields?.['first-name'] || user?.credentials?.metaData?.firstName

  const apiKey = process.env.REACT_APP_VIDEO_SDK_API_KEY
  const isTeacher = membership?.name === membershipNameType.Teacher || false

  const AllowedUsers = [membershipNameType.Teacher, membershipNameType.Pro]

  useEffect(() => {
    const initializeSDk = () => {
      const config = {
        containerId: 'meeting-container-id', // div id load frame to that div
        name: name, // name of current user
        meetingId: meetingId,
        apiKey: apiKey,
        redirectOnLeave: 'http://' + window.location.host + (isTeacher ? DASHBOARD : ACCOUNT),
        screenShareEnabled: true,
        whiteboardEnabled: true,
        chatEnabled: true,
        webcamEnabled: false,
        layout: {
          type: 'SPOTLIGHT', // "SPOTLIGHT" | "SIDEBAR" | "GRID"
          priority: 'PIN', // "SPEAKER" | "PIN",
          // gridSize: 3,
        },
        participantCanToggleSelfMic: true,
        raiseHandEnabled: true,
        micEnabled: true,
        permissions: {
          pin: true,
          askToJoin: false,
          toggleParticipantWebcam: false,
          toggleParticipantMic: true,

          // only teacher can
          removeParticipant: isTeacher,
          endMeeting: isTeacher,
          // changeLayout: true, //can change layout
        },
        branding: {
          enabled: false,
        },
        joinScreen: {
          visible: true, // Show the join screen ?
          // title: "Daily scrum", // Meeting title
          meetingUrl: window.location.href, // Meeting joining url
        },
        isRecorder: false,
        recordingEnabled: false,
        // participantCanLeave: false, // if false, leave button won't be visible
        notificationSoundEnabled: true,
        debug: false,
      }
      const meeting = new VideoSDKMeeting()
      meeting.init(config)
    }
    async function validateMeetingID() {
      try {
        const res = await validateRoom(id)
        if (res?.status === 200) {
          initializeSDk()
        } else {
          // endSession(id)
          if (isTeacher) {
            history.push(DASHBOARD)
          } else {
            history.push(ACCOUNT)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    if (props?.location?.pathname.slice(0, 4) === '/pro') {
      if (AllowedUsers.indexOf(membership?.name) === -1) {
        if (!isTeacher) {
          history.push(DASHBOARD)
        }
        history.push(ACCOUNT)
      }
    }
    if (name) {
      validateMeetingID()
    }
  }, [name])

  return <div style={{ height: '100vh' }} id='meeting-container-id' />
}
export default Meet
