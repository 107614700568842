import { membershipNameType } from '../shared/constant'
const { REACT_APP_BLACK_LIST_MEMBERSHIP_STREAKING } = process.env

export const isEnableStreaking = (membershipPlan: string, membershipId: string): boolean => {
  const isFree = membershipPlan === membershipNameType.Free
  const memberShipIDs = REACT_APP_BLACK_LIST_MEMBERSHIP_STREAKING?.toString().split('_') || []
  if (isFree || memberShipIDs?.indexOf(membershipId) >= 0) {
    return false
  }
  return true
}
