import { connect } from 'react-redux'
import React, { useEffect, useRef } from 'react'
import { logoutUser } from '../../redux/actions/userActions'
import history from '../../browserHistory'
import Header from '../../containers/header/Header'
import { DASHBOARD, INTRODUCTION, STORY_FINISHED } from 'paths'
import { idValidAccount, isSunday, parseQuery } from '../../utils/utils'
import { membershipNameType } from 'shared/constant'
declare let MemberStack: any
const Login: React.FC = (props: any) => {
  const fgPwRef = useRef<HTMLAnchorElement>(null)
  const queryString = useRef<string>(location.search)
  useEffect(() => {
    MemberStack.onReady.then((member: any) => {
      const query = parseQuery(queryString.current)
      if (query?.hasOwnProperty('forgot-password')) {
        if (fgPwRef?.current) {
          setTimeout(() => {
            fgPwRef.current?.click()
          }, 500)
        }
      }
    })
  }, [])
  useEffect(() => {
    const isTeacher = props.membership?.name === membershipNameType.Teacher
    if (props.authenticated) {
      if (isTeacher) {
        // history.push(DASHBOARD)
        history.push(INTRODUCTION)
      } else if (idValidAccount(props.membership?.name, props.membership?.status) || isSunday()) {
        history.push(INTRODUCTION)
      } else {
        history.push(STORY_FINISHED)
      }
    }
  }, [props])

  return (
    <>
      <Header />
      <div className='section-halves'>
        <div id='w-node-95d6fd9cfecd-66661950' className='section-halves-content'>
          <div className='justify-content-center text-center'>
            <h1 data-w-id='a0256642-0059-c1f4-e8d1-95d6fd9cfecf' className='heading-7'>
              Hey there!
            </h1>
            <div className='text-large-2'>Sign in using your credentials.</div>
            <div className='card-form w-form'>
              <form
                id='email-form'
                name='email-form'
                data-name='Email Form'
                data-ms-form='login'
                className='form-2 margin-top'
              >
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-input-2 w-input'
                    maxLength={256}
                    name='Email'
                    data-name='Email'
                    placeholder='Email Address'
                    id='Email'
                    data-ms-member='email'
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    className='form-input-2 w-input'
                    maxLength={256}
                    name='Password'
                    data-name='Password'
                    placeholder='Password'
                    id='Password'
                    data-ms-member='password'
                  />
                  <div className='row row-justify-end'>
                    <a href='#/ms/password-reset' ref={fgPwRef} className='text-small form-hint-text'>
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <input
                  type='submit'
                  value='Sign In'
                  data-wait='Please
                wait...'
                  className='button-2 w-button'
                />
              </form>
              <div className='form-feedback bg-success w-form-done'>
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className='form-feedback bg-error w-form-fail'>
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className='text-small'>
              Don&#x27;t have an account?&nbsp;
              <a href='https://maayot.com' className='link-2'>
                Sign up here
              </a>
            </div>
          </div>
        </div>
        <img
          src='images/sign-in.png'
          width='506'
          srcSet='
          images/sign-in-p-500.png   500w,
          images/sign-in-p-800.png   800w,
          images/sign-in-p-1080.png 1080w,
          images/sign-in-p-1600.png 1600w,
          images/sign-in.png        1840w
        '
          sizes='(max-width: 991px) 100vw, 50vw'
          alt=''
          className='full-bleed-image'
        />
      </div>
    </>
  )
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
  user: state.user,
  UI: state.UI,
  authenticated: state.user.authenticated,
  membership: state.user.membership,
})
const mapActionsToProps = {
  logoutUser,
}
export default connect(mapStateToProps, mapActionsToProps)(Login)
