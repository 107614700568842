import { INTRODUCTION, LIVE_MEET, PRO_MEET } from 'paths'
import React, { useEffect } from 'react'
import { createRoom } from 'services/meet'
import history from '../../browserHistory'
import { membershipNameType } from 'shared/constant'
import { useSelector } from 'react-redux'

export const createMeeting = async () => {
  const meetingId = 'xxxxyxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
  // console.log("http://" + window.location.host + "?meetingId=" + meetingId)
  return meetingId
}

const Meet: React.FC = () => {
  const membership = useSelector((state) => state?.['user']?.membership)
  const isTeacher = membership?.name === membershipNameType.Teacher || false

  useEffect(() => {
    if (!isTeacher) {
      history.push(INTRODUCTION)
    }
  }, [])

  const onClickCreateMeet = async (type: string) => {
    const id = await createMeeting()
    const Meeting = await createRoom(id)
    if (Meeting?.status === 200) {
      history.push(`${type}/${Meeting?.['data']?.customRoomId}`)
    }
  }

  return (
    <div className='createMeetWrapper'>
      <div className='createMeet' onClick={() => onClickCreateMeet(LIVE_MEET)}>
        <button className='button-2 w-button'>Create General Meeting</button>
      </div>
      <div className='createMeet' onClick={() => onClickCreateMeet(PRO_MEET)}>
        <button className='button-2 w-button'>Create Pro Meeting</button>
      </div>
    </div>
  )
}
export default Meet
