// user reduce type
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_LEVEL = 'SET_LEVEL'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'
export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP'
// app reducer types
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

// app reducer stories
export const SET_STORY_LOADING = 'SET_STORY_LOADING'
export const SET_STORY = 'SET_STORY'
export const SET_STORIES = 'SET_STORIES'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_LAST_STEP = 'SET_LAST_STEP'
export const SET_PREVENT_REDIRECT = 'SET_PREVENT_REDIRECT'
export const SET_SHOW_RECORDING_SUCCESS = 'SET_SHOW_RECORDING_SUCCESS'

//app reducer quizz
export const SET_QUIZ = 'SET_QUIZ'
export const RESET_QUIZ = 'RESET_QUIZ'
export const SET_SUBMIT_QUIZ = 'SET_SUBMIT_QUIZ'
export const SET_GENERAL_RESULT = 'SET_GENERAL_RESULT'

//app reducer question
export const SET_QUESTION = 'SET_QUESTION'
export const SET_QUESTION_ANSWER_LIST = 'SET_QUESTION_ANSWER_LIST'
export const CLEAR_QUESTION_ANSWER_LIST = 'CLEAR_QUESTION_ANSWER_LIST'
export const SET_SUBMIT_QUESTION = 'SET_SUBMIT_QUESTION'
export const SET_CURRENT_ANSWER = 'SET_CURRENT_ANSWER'

//my Account
export const ACCOUNT_SET_PROFILE = 'ACCOUNT_SET_PROFILE'
export const ACCOUNT_SET_LEVEL_DONE = 'ACCOUNT_SET_LEVEL_DONE'
export const ACCOUNT_SET_CHARACTER_DONE = 'ACCOUNT_SET_CHARACTER_DONE'
export const ACCOUNT_SET_FIRST_NAME_DONE = 'ACCOUNT_SET_FIRST_NAME_DONE'
export const ACCOUNT_SET_EMAIL_DONE = 'ACCOUNT_SET_EMAIL_DONE'
export const ACCOUNT_SET_PASSWORD_DONE = 'ACCOUNT_SET_PASSWORD_DONE'
export const ACCOUNT_UPDATE_ERRORS = 'ACCOUNT_UPDATE_ERRORS'
export const ACCOUNT_SET_HACK_CHINESE = 'ACCOUNT_SET_HACK_CHINESE'
export const ACCOUNT_REMOVE_HACK_CHINESE = 'ACCOUNT_REMOVE_HACK_CHINESE'

export const ACCOUNT_SET_SKRITTER = 'ACCOUNT_SET_SKRITTER'
export const ACCOUNT_REMOVE_SKRITTER = 'ACCOUNT_REMOVE_SKRITTER'

//streak

export const CURRENT_STREAK_LOADING = 'CURRENT_STREAK_LOADING'
export const LONGEST_STREAK_LOADING = 'LONGEST_STREAK_LOADING'
export const SET_LONGEST_STREAK = 'SET_LONGEST_STREAK'
export const SET_CURRENT_STREAK = 'SET_CURRENT_STREAK'
export const CURRENT_MONTH_STREAK_LOADING = 'CURRENT_MONTH_STREAK_LOADING'
export const SET_MONTH_STREAK = 'SET_MONTH_STREAK'

//app reducer dashboard
export const SET_CORRECTIONS = 'SET_CORRECTIONS'
export const SET_CURRENT_CORRECTION = 'SET_CURRENT_CORRECTION'
export const SET_CHINESE_WORD_FONT_SIZE = 'SET_CHINESE_WORD_FONT_SIZE'

//app reducer recording
export const SET_CURRENT_RECORDING = 'SET_CURRENT_RECORDING'

// app reducer Corrections
export const SET_CORRECTION_DATA = 'SET_CORRECTION_DATA'
export const SET_CORRECTION_COUNT = 'SET_CORRECTION_COUNT'
export const SET_CORRECTION_ANSWER = 'SET_CORRECTION_ANSWER'
export const SET_CORRECTION_ERRORS = 'SET_CORRECTION_ERRORS'

export const SET_CORRECTION_STORY = 'SET_CORRECTION_STORY'
export const SET_CORRECTION_QUESTION = 'SET_CORRECTION_QUESTION'
export const SET_PAST_STORIES = 'SET_STORIES'

// app reducer on-boarding

export const SET_ALL_LANGUAGE = 'SET_ALL_LANGUAGE'
export const SET_LANGUAGE_INFO = 'SET_LANGUAGE_INFO'
export const SET_ALL_WORDS = 'SET_ALL_WORDS'
export const SET_WORDSLIST = 'SET_WORDSLIST'
export const SET_WORD_RESULT = 'SET_WORD_RESULT'
export const SET_IS_RESULT = 'SET_IS_RESULT'
export const SET_STEPPER_STEP = 'SET_STEPPER_STEP'
export const RESET_ONBOARDING = 'RESET_ONBOARDING'
export const IS_REFUSER = 'IS_REFUSER'
export const MANAGE_WORDLIST_LOADER = 'MANAGE_WORDLIST_LOADER'

// learn about maayot
export const SET_LEARN_ABOUT_LOADING = 'SET_LEARN_ABOUT_LOADING'
export const SET_LEARN_ABOUT = 'SET_LEARN_ABOUT'
export const SET_LEARN_ABOUT_TYPE = 'SET_LEARN_ABOUT_TYPE'
export const SET_LEARN_ABOUT_TITLE = 'SET_LEARN_ABOUT_TITLE'
export const SET_LEARN_ABOUT_ANSWER = 'SET_LEARN_ABOUT_ANSWER'
