import React from 'react'
import { connect } from 'react-redux'

const User: React.FC = () => {
  return <h1>User Login Success</h1>
}

const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
})
export default connect(mapStateToProps)(User)
