import React, { useState, useEffect, useRef } from 'react'
import {
  getProfile,
  saveLevel,
  saveFirstName,
  savePassword,
  saveEmail,
  SaveCharacter,
} from '../../redux/actions/myAccountAction'
import { logoutUser } from '../../redux/actions/userActions'

import { connect } from 'react-redux'
import FormInput from '../../containers/form/FormInput'
import NotificationBox from '../../containers/form/NotificationBox'
import PasswordInput from '../../containers/form/PasswordInput'
interface IMyAccountProps {
  credentials?: any
}
const MyAccount: React.FC<IMyAccountProps> = (props: any) => {
  const { credentials, saveFirstName, saveEmail, savePassword, logOut } = props
  const { memberId } = credentials
  useEffect(() => {
    if (!memberId) {
      // CheckAuthentication()
    }
  }, [memberId])

  const firstNameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)

  const [isEditFirstName, setIsEditFirstName] = useState(false)
  const [firstNameSubmitting, setFirstNameSubmitting] = useState(false)
  const [isEditEmail, setIsEditEmail] = useState(false)
  const [emailSubmitting, setEmailSubmitting] = useState(false)
  const [firstName, setFirstName] = useState(
    credentials.customFields?.['first-name'] || credentials.metaData?.firstName,
  )
  const [email, setEmail] = useState(credentials.email)
  const [notify, setNotify] = useState({ label: '', isError: false })

  useEffect(() => {
    const _firstName = credentials.customFields?.['first-name'] || credentials.metaData?.firstName
    setFirstName(_firstName)
  }, [credentials.customFields?.['first-name'], credentials.metaData?.firstName])

  useEffect(() => {
    setEmail(credentials.email)
  }, [credentials.email])

  const changeFirstName = (e) => {
    setFirstName(e.target.value)
    clearNotify()
  }

  const notifySuccess = () => {
    setNotify({ label: 'Your submission has been received!', isError: false })
  }
  const _saveFirstName = () => {
    if (!firstNameSubmitting) {
      setFirstNameSubmitting(true)
      if (firstName === credentials.metaData?.firstName) {
        setFirstNameSubmitting(false)
        setIsEditFirstName(false)
        return
      }

      saveFirstName(firstName, memberId)
        .then(() => {
          setFirstNameSubmitting(false)
          notifySuccess()
          setIsEditFirstName(false)
        })
        .catch((e) => {
          setFirstNameSubmitting(false)
          setNotify({ label: e || 'Error first name input', isError: true })
          setIsEditFirstName(true)
          firstNameRef?.current?.focus()
        })
    }
  }

  const changeEmail = (e) => {
    setEmail(e.target.value)
    clearNotify()
  }

  const _saveEmail = () => {
    if (!emailSubmitting) {
      setEmailSubmitting(true)
      if (email === credentials.email) {
        setEmailSubmitting(false)
        setIsEditEmail(false)
        return
      }
      saveEmail(email, memberId)
        .then(() => {
          setEmailSubmitting(false)
          notifySuccess()
          setIsEditEmail(false)
        })
        .catch((e) => {
          setEmailSubmitting(false)
          setNotify({ label: e || 'Error email input', isError: true })
          setIsEditEmail(true)
          emailRef?.current?.focus()
        })
    }
  }

  const onPasswordSuccess = () => {
    notifySuccess()
  }

  const onChangePassword = () => {
    clearNotify()
  }

  const onPasswordError = (label) => {
    setNotify({ label: label || 'Password error', isError: true })
  }
  const clearNotify = () => {
    setNotify({ label: '', isError: true })
  }

  const onLogOut = () => {
    if (logOut) {
      logOut()
    }
  }

  return (
    <>
      <div className='account-right m-b-white'>
        <h4 className={'m-h4 m-gray-1'}>My Account</h4>
        <NotificationBox clearNotify={clearNotify} {...notify} />
        <FormInput
          label={'First Name'}
          value={firstName}
          onSave={_saveFirstName}
          onChange={changeFirstName}
          isEdit={isEditFirstName}
          onEditMode={setIsEditFirstName}
          ref={firstNameRef}
          isSubmitting={firstNameSubmitting}
        />
        <FormInput
          label={'Email address'}
          value={email}
          onSave={_saveEmail}
          onChange={changeEmail}
          isEdit={isEditEmail}
          onEditMode={setIsEditEmail}
          ref={emailRef}
          isSubmitting={emailSubmitting}
        />
        <PasswordInput
          savePassword={savePassword}
          memberId={memberId}
          email={email}
          onChangePassword={onChangePassword}
          onPasswordSuccess={onPasswordSuccess}
          onPasswordError={onPasswordError}
        />
        <div>
          <div className={'logout-btn '} onClick={onLogOut}>
            <span className={'m-gray-1 m-pointer'}>Log Out</span>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
  membership: state.user.membership,
})
const mapActionsToProps = {
  getProfile,
  saveLevel,
  SaveCharacter,
  saveFirstName,
  saveEmail,
  savePassword,
  logOut: logoutUser,
}
export default connect(mapStateToProps, mapActionsToProps)(MyAccount)
