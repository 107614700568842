import React from 'react'
import { converterType, characterConst } from '../../shared/constant'
interface WordProps {
  index?: any
  text?: any
  onClick?: any
  isActive?: boolean
  isNewWord?: boolean
  isOldWord?: boolean
  pinYinText?: any
  chineseWordFontSize?: any
}

const Word: React.FC<WordProps> = (props: any) => {
  const { index, text, isNewWord, isOldWord, isActive, onClick, pinYinText, chineseWordFontSize } = props
  const onWordClick = (e) => {
    onClick && onClick(e, index)
  }
  const className =
    `${onClick ? `word-translate` : ''} ` +
    `${isActive ? 'word-active' : ''} ` +
    `${isNewWord ? 'newword-highlight' : ''}` +
    `${isOldWord ? 'oldword-highlight' : ''}`
  return (
    <span className={`d-grid ${pinYinText ? 'ml-2 mr-2' : ''}`} onClick={onWordClick}>
      <span className={'m-flx m-center'}>
        <span
          style={{ fontSize: chineseWordFontSize?.sessionTitle, lineHeight: '1.5', letterSpacing: '1.5px' }}
          className={`${className}m-al-c`}
        >
          {globalThis.characterPreference === characterConst.traditional
            ? converterType.converterTraditional(text)
            : converterType.converterSimplified(text)}
        </span>
      </span>
      {pinYinText && <span className='pinyin-word m-al-c'>{pinYinText?.toLowerCase()}</span>}
    </span>
  )
}
export default Word
