import React from 'react'
import Navigation, { NavStatusConst } from '../navigation'
import history from '../../browserHistory'
import { MemberStepInStory, StepMap2Link } from '../../shared/constant'
import { connect } from 'react-redux'

interface IWithNavProps {
  children?: any
  step?: any
  lastStep?: any
  story?: any
}

const WithNav: React.FC<IWithNavProps> = (props: IWithNavProps) => {
  const { step } = props

  const data = [
    {
      text: 'Introduction',
    },
    {
      text: 'Story & Listening',
    },
    {
      text: 'Quiz',
    },
    {
      text: 'Writing',
    },
    {
      text: 'Finish',
    },
  ]
  const _data = data.map((o, i) => {
    return {
      text: o.text,
      step: i + 1,
      status: i < step ? NavStatusConst.finished : i == step ? NavStatusConst.active : NavStatusConst.notFinish,
    }
  })
  return (
    <>
      <Navigation data={_data} activeStep={step} />
      {props.children}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  lastStep: state.story.lastStep,
  story: state.story.currentStory,
})
export default connect(mapStateToProps)(WithNav)
