import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import BeginnerStar from '../../containers/star/BeginnerStar'
import AdvancedStar from '../../containers/star/AdvancedStar'
import IntermediateStar from '../../containers/star/IntermediateStar'
import { getIntro, setStep, saveLastStep, translate } from '../../redux/actions/storyAction'
import CountDown from '../../containers/countdown/CountDown'
import { idValidAccount, toUpperFirstLetter } from '../../utils/utils'
import { Link } from 'react-router-dom'
import { countDownType, levelConst, membershipType, storyStep, SUNDAY, wordType } from '../../shared/constant'
import history from '../../browserHistory'
import { STORY_FINISHED } from 'paths'
import Loader from '../../containers/loader/loader'
import WithRedirect from '../../containers/WithRedirect'
import { isSunday } from '../../utils/utils'
import { converterType, characterConst } from '../../shared/constant'
import StoryTitle from './StoryTitle'
import Translate from 'containers/popup/Translate'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'
import { getQuiz, getGeneralResult } from 'redux/actions/quizAction'

const Introduction: React.FC = (props: any) => {
  const { memberId, customFields } = props.credentials
  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const popupRef = useRef<HTMLInputElement>(null)
  const level = props.level || customFields.level || props.credentials?.level
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)

  useEffect(() => {
    if (memberId && memberId !== '') {
      if (JSON.stringify(props.story) === '{}') {
        props.getIntro(memberId, level)
      } else {
        const storyId = props?.story?.id
        if (!props?.quizDetail?.quiz && storyId && level && memberId) {
          props.getQuiz(storyId, level, memberId).then((result) => {
            if (result.memberAnswer) {
              props.getGeneralResult(storyId)
            }
          })
        }
      }
    }
  }, [memberId, props.story])

  useEffect(() => {
    window.scrollTo(0, 0)
    props.setStep(storyStep.Intro)
    if (props.authenticated) {
      if (!idValidAccount(props.membership?.name, props.membership?.status) && !isSunday()) {
        history.push(STORY_FINISHED)
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.story])

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    const newWord1 =
      globalThis.characterPreference === characterConst.traditional
        ? converterType.converterTraditional(props.story?.newWord1)
        : converterType.converterSimplified(props.story?.newWord1)
    const newWord2 =
      globalThis.characterPreference === characterConst.traditional
        ? converterType.converterTraditional(props.story?.newWord2)
        : converterType.converterSimplified(props.story?.newWord2)
    setTransVisible(true)
    const isOldWord = props?.story?.oldWords?.includes(word)
    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = props?.story?.storyTitleContent.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (props?.user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], props?.user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }
  return (
    <div ms-membership='free' className='section-2'>
      {(JSON.stringify(props.story) !== '{}' && (
        <div className='section-intro w-container'>
          <div className='w-row'>
            <div className='w-col w-col-6 content-left'>
              <span className='introduction-title'>Introduction</span>
            </div>
            <div className='w-col w-col-6 content-right'>
              <div className='section-countdown'>
                <div className='countdown-title'>Story will change in </div>
                <CountDown type={countDownType.Day} />
              </div>
            </div>
          </div>
          {(props.errors === 'Error' || props.errors === null) && props?.story?.storyTitleContent && (
            <div className='w-row'>
              <div className='w-col w-col-6 content-left'>
                <div className='w-col w-col-6 section-story-img-mobile m-center m-al-c'>
                  <img className='intro-img' alt={props.story?.englishTitle} src={props.story?.image} />
                </div>
                <div className='w-col section-story-name'>
                  <div
                    style={{ fontSize: chineseWordFontSize?.sessionTitle, lineHeight: '1.5', letterSpacing: '1.5px' }}
                    className='story-name'
                  >
                    {globalThis.characterPreference === characterConst.traditional ? (
                      <StoryTitle
                        storyTitle={props?.story?.storyTitleContent}
                        showPinYin={false}
                        story={props?.story}
                        handleClickWord={handleClickWord}
                        activeIndex={activeIndex}
                      />
                    ) : (
                      <StoryTitle
                        storyTitle={props?.story?.storyTitleContent}
                        showPinYin={false}
                        story={props?.story}
                        handleClickWord={handleClickWord}
                        activeIndex={activeIndex}
                      />
                    )}
                  </div>
                  <div className='story-name-secondary word-break-class'>{props.story?.englishTitle}</div>
                  <div className='section-member-level'>
                    <span className='member-level'>{toUpperFirstLetter(level)}</span>
                    &nbsp;
                    {level && level.toLowerCase() === levelConst.beginner.toLowerCase() && <BeginnerStar />}
                    {level && level.toLowerCase() === levelConst.intermediate.toLowerCase() && <IntermediateStar />}
                    {level && level.toLowerCase() === levelConst.advanced.toLowerCase() && <AdvancedStar />}
                  </div>
                </div>

                <div className='w-col story-summary'>{props.story?.storyIntroduction}</div>

                <div className='w-col story-button mobile-button-stick'>
                  <Link to={{ pathname: `/story/${props.story?.id}` }}>
                    <button className='button-2 w-button'>Start Reading</button>
                  </Link>
                </div>
              </div>
              <div className='w-col w-col-6 section-story-img m-center m-al-c'>
                <img className='intro-img' alt={props.story?.englishTitle} src={props.story?.image} />
              </div>
            </div>
          )}
        </div>
      )) || (
        <div className='section-intro w-container'>
          <Loader />
        </div>
      )}
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  level: state.user.level,
  credentials: state.user.credentials,
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  authenticated: state.user.authenticated,
  membership: state.user.membership,
  errors: state.UI.errors,
  lastStep: state.story.lastStep,
  stories: state.story.stories,
  story: JSON.stringify(state.story.currentStory) === '{}' ? {} : state.story.currentStory,
  isGetData:
    (state.user.credentials?.level?.length > 0 || state.user.credentials.customFields?.level?.length > 0) &&
    !state.UI.errors &&
    JSON.stringify(state.story.currentStory) === '{}'
      ? true
      : false,
})
const mapActionsToProps = {
  getIntro,
  setStep,
  saveLastStep,
  //
  getQuiz,
  getGeneralResult,
}
export default connect(mapStateToProps, mapActionsToProps)(WithRedirect(Introduction))
