import React from 'react'
import { connect } from 'react-redux'
import CountDown from '../../containers/countdown/CountDown'
import { countDownType, membershipNameType } from '../../shared/constant'

interface IStoryFinishedProps {
  readAgain: any
}

const StoryFinished: React.FC<IStoryFinishedProps> = (props: any) => {
  const { membership, readAgain } = props

  const isFree = membership?.name == membershipNameType.Free
  const isPremium = membership?.name == membershipNameType.Premium
  const isStandard = membership?.name == membershipNameType.Standard
  const isSchool = membership?.name == membershipNameType.School
  const isPro = membership?.name == membershipNameType.Pro
  const isTeacher = membership?.name == membershipNameType.Teacher
  // const isPaid = isPremium || isStandard || isSchool || isPro
  const isPaid = isPremium || isStandard || isSchool || isPro || isTeacher

  return (
    <div ms-membership='free' className='section-2'>
      <div className='section-story w-container'>
        <div className='w-row'>
          <div className='w-col w-col-6 content-left'>
            <span className='introduction-title'>Story Finished</span>
          </div>
          {isPaid && (
            <div className='w-col w-col-6 content-right'>
              <div className='section-countdown'>
                <div className='countdown-title'>Story will change in </div>
                <CountDown type={countDownType.Day} />
              </div>
            </div>
          )}
        </div>
        <div className='w-row section-story-finished'>
          <div className='w-col-12 icon-finished'>
            <img src='/images/icons/icon-finished.jpg' alt='' />
          </div>
          <div className='w-col-12 story-finished-title'>
            {isFree && <span>Story is finished</span>}
            {isPaid && <span>Congratulation!</span>}
          </div>
          {isPaid && (
            <div className='w-col-12 story-finished-upgrade'>
              <p>You have finished today’s story, see you tomorrow!</p>
              <a className='button-4 w-inline-block' onClick={readAgain}>
                Read Again
              </a>
            </div>
          )}
          {isFree && (
            <>
              <div className='w-col-12 story-finished-countdown section-countdown'>
                <p>As a free user, your next story will be accessible on Sunday, in:</p>
                <CountDown type={countDownType.Week} />
              </div>
              <div className='w-col-12 story-finished-upgrade'>
                <div>Or upgrade your subscription</div>
                <p>
                  Boost your Chinese with daily unique stories, one-click dictionary, exercises to test your
                  comprehension and much more.
                </p>
                <a
                  href={'https://www.maayot.com/account'}
                  className='button-4 w-inline-block button-normal'
                  target={'_blank'}
                >
                  Upgrade Now
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
  membership: state.user.membership,
})
const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(StoryFinished)
