import { membershipType } from '../../shared/constant'
import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_CREDENTIALS,
  SET_MEMBERSHIP,
  LOADING_USER,
  ACCOUNT_SET_LEVEL_DONE,
  ACCOUNT_SET_CHARACTER_DONE,
  ACCOUNT_SET_FIRST_NAME_DONE,
  ACCOUNT_SET_EMAIL_DONE,
  ACCOUNT_SET_PASSWORD_DONE,
  ACCOUNT_UPDATE_ERRORS,
  ACCOUNT_SET_HACK_CHINESE,
  ACCOUNT_REMOVE_HACK_CHINESE,
  ACCOUNT_SET_SKRITTER,
  ACCOUNT_REMOVE_SKRITTER,
  SET_LEVEL,
} from '../types'

interface IUserState {
  level: string
  authenticated: boolean
  credentials: {
    memberId: string
    email: string
    metaData: {
      firstName: string
    }
    customFields: {
      level: string
      'first-name': string
    }
    level: string
  }
  membership: {
    id: string
    name: string
    amount: number
    oneTime: boolean
    status: string
    plan: string
    type: string
  }
  loading: boolean
  accountError: string
  user_auth_token: string
  skritter_token: string
  skritter: any
}

let user: any = {}
try {
  user = JSON.parse(localStorage.getItem('user') || '{}')
  if (!user.status) {
    user = {}
  }
} catch (error) {
  user = {}
}

const initialState: IUserState = {
  authenticated: JSON.stringify(user) !== '{}' ? true : false,
  level: '',
  credentials: {
    memberId: '',
    email: '',
    customFields: {
      level: '',
      'first-name': '',
    },
    metaData: {
      firstName: '',
    },
    level: '',
  },
  membership: {
    id: '',
    name: JSON.stringify(user) !== '{}' ? user.membershipName : '',
    amount: 0,
    oneTime: false,
    status: JSON.stringify(user) !== '{}' ? user.status : membershipType.Free,
    plan: '',
    type: '',
  },
  loading: false,
  accountError: '',
  user_auth_token: '',
  skritter_token: '',
  skritter: '',
}
export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_LEVEL:
      return {
        ...state,
        level: action.payload,
      }
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        credentials: {
          ...state.credentials,
          ...action.payload.credentials,
        },
        membership: {
          ...state.membership,
          ...action.payload.membership,
        },
      }

    case SET_UNAUTHENTICATED:
      return {
        ...initialState,
        authenticated: false,
      }
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        ...action.payload,
      }
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      }
    case SET_CREDENTIALS:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          ...action.payload.credentials,
        },
      }
    case SET_MEMBERSHIP:
      return {
        ...state,
        membership: {
          ...state.membership,
          ...action.payload.membership,
        },
      }
    case ACCOUNT_SET_FIRST_NAME_DONE:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          customFields: {
            ...state.credentials.customFields,
            'first-name': action.payload,
          },
        },
      }
    case ACCOUNT_SET_EMAIL_DONE:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          email: action.payload,
        },
      }
    case ACCOUNT_SET_LEVEL_DONE:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          customFields: {
            ...state.credentials.customFields,
            level: action.payload,
          },
        },
      }
    case ACCOUNT_SET_CHARACTER_DONE:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          customFields: {
            ...state.credentials.customFields,
            character: action.payload,
          },
        },
      }
    case ACCOUNT_SET_PASSWORD_DONE:
      return {
        ...state,
      }
    case ACCOUNT_UPDATE_ERRORS:
      return {
        ...state,
        accountError: action.payload,
      }
    case ACCOUNT_SET_HACK_CHINESE:
      return {
        ...state,
        user_auth_token: action.payload.token,
      }
    case ACCOUNT_REMOVE_HACK_CHINESE:
      return {
        ...state,
        user_auth_token: '',
      }
    case ACCOUNT_SET_SKRITTER:
      return {
        ...state,
        skritter_token: action.payload.token,
        skritter: action.payload.skritter,
      }
    case ACCOUNT_REMOVE_SKRITTER:
      return {
        ...state,
        skritter_token: '',
        skritter: '',
      }

    default:
      return state
  }
}
