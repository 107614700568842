import MAAYOT_API, { JSON_HEADER } from './config'
import {
  ACCOUNT_SET_LEVEL_DONE,
  ACCOUNT_SET_FIRST_NAME_DONE,
  ACCOUNT_SET_EMAIL_DONE,
  ACCOUNT_SET_PASSWORD_DONE,
  ACCOUNT_UPDATE_ERRORS,
  SET_CREDENTIALS,
  ACCOUNT_SET_CHARACTER_DONE,
} from '../types'

const clearLocal = () => {
  localStorage.removeItem('persist:storymaayot')
  localStorage.removeItem('persist:quizmaayot')
  localStorage.removeItem('persist:questionmaayot')
  localStorage.removeItem('persist:streakmaayot')
}

export const getProfile = (memberId: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    fetch(`${MAAYOT_API}profile/${memberId}`, {
      method: 'GET',
      headers: JSON_HEADER,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: SET_CREDENTIALS,
            payload: {
              credentials: data.member,
            },
          })
          globalThis.characterPreference = data.member.customFields.character
          resolve(data.member)
        } else {
          dispatch({
            type: ACCOUNT_UPDATE_ERRORS,
            payload: 'Error',
          })
          reject('Error')
        }
      })
  })
}

export const saveLevel = (level: string, memberId: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    const content = {
      id: memberId,
      level: level?.toLowerCase(),
    }
    return fetch(`${MAAYOT_API}profile/custom/level`, {
      method: 'PUT',
      headers: JSON_HEADER,
      body: JSON.stringify(content),
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          clearLocal()
          const level = data.member?.customFields?.level || data.member.level || 'Null'
          dispatch({
            type: ACCOUNT_SET_LEVEL_DONE,
            payload: level.toLowerCase(),
          })
          resolve(data)
        } else {
          const err = Array.isArray(data.message) ? data.message[0] : data.message
          dispatch({
            type: ACCOUNT_UPDATE_ERRORS,
            payload: err,
          })
          reject(err)
        }
      })
  })
}
export const SaveCharacter = (character: string, memberId: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    const content = {
      id: memberId,
      UpdateCharacter: character,
    }
    return fetch(`${MAAYOT_API}profile/custom/character`, {
      method: 'PUT',
      headers: JSON_HEADER,
      body: JSON.stringify(content),
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          const character = data.member?.customFields?.character || data.member.character || 'Null'
          dispatch({
            type: ACCOUNT_SET_CHARACTER_DONE,
            payload: character,
          })
          resolve(data)
        } else {
          const err = Array.isArray(data.message) ? data.message[0] : data.message
          dispatch({
            type: ACCOUNT_UPDATE_ERRORS,
            payload: err,
          })
          reject(err)
        }
      })
  })
}

export const saveFirstName = (firstName: string, memberId: string) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const content = {
        id: memberId,
        firstName,
      }
      return fetch(`${MAAYOT_API}profile/custom/first-name`, {
        method: 'PUT',
        headers: JSON_HEADER,
        body: JSON.stringify(content),
      })
        .then((resData) => resData.json())
        .then((data) => {
          if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
            dispatch({
              type: ACCOUNT_SET_FIRST_NAME_DONE,
              payload: data.member?.customFields?.['first-name'] || data.member?.metaData?.['firstName'] || 'NoName',
            })
            resolve(data)
          } else {
            const err = Array.isArray(data.message) ? data.message[0] : data.message
            dispatch({
              type: ACCOUNT_UPDATE_ERRORS,
              payload: err,
            })
            reject(err)
          }
        })
    })
  }
}

export const saveEmail = (email: string, memberId: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    const content = {
      id: memberId,
      email,
    }
    return fetch(`${MAAYOT_API}profile/custom/email`, {
      method: 'PUT',
      headers: JSON_HEADER,
      body: JSON.stringify(content),
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: ACCOUNT_SET_EMAIL_DONE,
            payload: data.member?.email || 'Null',
          })
          resolve(data)
        } else {
          const err = Array.isArray(data.message) ? data.message[0] : data.message
          dispatch({
            type: ACCOUNT_UPDATE_ERRORS,
            payload: err,
          })
          reject(err)
        }
      })
  })
}

export const savePassword =
  (oldPassword: string, newPassword: string, confirmNewPassword: string, email: string) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const passwordObj = {
        email,
        oldPassword,
        newPassword,
        confirmNewPassword,
      }

      return fetch(`${MAAYOT_API}profile/change-password`, {
        method: 'POST',
        headers: JSON_HEADER,
        body: JSON.stringify({ ...passwordObj }),
      })
        .then((resData) => resData.json())
        .then((data) => {
          if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
            dispatch({
              type: ACCOUNT_SET_PASSWORD_DONE,
              payload: data,
            })
            resolve(data)
          } else {
            const err = Array.isArray(data.message) ? data.message[0] : data.message
            dispatch({
              type: ACCOUNT_UPDATE_ERRORS,
              payload: err,
            })
            reject(err)
          }
        })
    })
  }
