import React, { useEffect } from 'react'
import history from '../../browserHistory'
import { ACCOUNT } from '../../paths'
import Loader from '../../containers/loader/loader'

const SuccessPaid: React.FC = (props: any) => {
  useEffect(() => {
    history.push(ACCOUNT)
  }, [])
  return <Loader />
}
export default SuccessPaid
