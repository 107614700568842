import { SET_CURRENT_RECORDING } from '../types'

interface IRecorderState {
  currentAudioRecording: any
}

const initialState: IRecorderState = {
  currentAudioRecording: {},
}

export default function recorderReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CURRENT_RECORDING:
      return {
        ...state,
        currentAudioRecording: action.payload,
      }
    default:
      return state
  }
}
