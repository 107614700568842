import { storyStep, StepMap2Enum, MemberStepInStory } from '../../shared/constant'
import {
  SET_CURRENT_STEP,
  SET_STORIES,
  SET_STORY,
  SET_LAST_STEP,
  SET_PREVENT_REDIRECT,
  SET_SHOW_RECORDING_SUCCESS,
  SET_STORY_LOADING,
} from '../types'

interface IStoryState {
  stories: any
  storyLoading: boolean
  currentStory: any
  currentStep: any
  lastStep: any
  lastStepEnum: any
  preventRedirect: boolean
  showRecordingSuccess: boolean
}

const initialState: IStoryState = {
  stories: [],
  storyLoading: true,
  currentStory: {},
  currentStep: storyStep.Intro,
  lastStep: null,
  lastStepEnum: null,
  preventRedirect: false,
  showRecordingSuccess: false,
}

export default function storyReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_STORY_LOADING:
      const tempPayload = action?.payload || {}
      return {
        ...state,
        ...tempPayload,
      }
    case SET_STORIES:
      return {
        ...state,
        stories: [...action.payload],
        currentStory: {},
      }
    case SET_STORY:
      return {
        ...state,
        currentStory: {
          ...action.payload,
        },
      }
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      }
    case SET_LAST_STEP:
      const lastStep = action.payload || MemberStepInStory.INTRODUCTION
      return {
        ...state,
        lastStep: lastStep,
        lastStepEnum: StepMap2Enum[lastStep],
      }
    case SET_PREVENT_REDIRECT:
      return {
        ...state,
        preventRedirect: action.payload,
      }
    case SET_SHOW_RECORDING_SUCCESS:
      return {
        ...state,
        showRecordingSuccess: action.payload,
      }
    default:
      return state
  }
}
