import React from 'react'

const AdvancedStar: React.FC = () => {
  return (
    <>
      <span className='fa fa-star fa-star-checked'></span>
      <span className='fa fa-star fa-star-checked'></span>
      <span className='fa fa-star fa-star-checked'></span>
    </>
  )
}

export default AdvancedStar
