import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import Countdown from 'react-countdown'
import history from '../../browserHistory'
import { countDownLabel, countDownCheckTime, milisInDay, countDownType, milisInWeek } from '../../shared/constant'
import { momentToMilis } from '../../utils/utils'

interface ICountdownProps {
  // level: string;
  type?: countDownType
}

const CountDown: React.FC<ICountdownProps> = (props: ICountdownProps) => {
  // check time countdown with GMT +0 and local timezone
  const currentDay = moment.utc()
  let timeCDMilis = milisInDay - momentToMilis(currentDay)
  if (props.type === countDownType.Week && currentDay.weekday() != 7) {
    timeCDMilis = milisInWeek - currentDay.weekday() * milisInDay - momentToMilis(currentDay)
  }

  let typeLabel = countDownLabel.Green
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: boolean
  }) => {
    if (completed) {
      return <h1>Coundown complete</h1>
    } else {
      typeLabel = countDownLabel.Green
      // Check time to render countdown color
      if (hours * 60 + minutes <= countDownCheckTime.Red) {
        typeLabel = countDownLabel.Red
      } else if (hours * 60 + minutes <= countDownCheckTime.Orange) {
        typeLabel = countDownLabel.Orange
      }
      // Render a countdown
      return (
        <div className='countdown'>
          {props.type === countDownType.Week && (
            <>
              <div className='w-row block-countdown'>
                <div className='w-col count-down-text level-week'>{days}</div>
                <div className='level-week-text'>Day</div>
              </div>
              &nbsp;
              <div className='w-row block-countdown'>
                <div className='w-col count-down-text level-week'>{hours}</div>
                <div className='level-week-text'>Hour</div>
              </div>
              &nbsp;
              <div className='w-row block-countdown'>
                <div className='w-col count-down-text level-week'>{minutes}</div>
                <div className='level-week-text'>Min</div>
              </div>
              &nbsp;
              <div className='w-row block-countdown'>
                <div className='w-col count-down-text level-week'>{seconds}</div>
                <div className='level-week-text'>Sec</div>
              </div>
            </>
          )}
          {props.type === countDownType.Day && (
            <>
              <div className={`count-down-text level-${typeLabel}`}>{hours}</div>
              &nbsp;
              <div className={`text-dots-mark text-${typeLabel}`}>:</div>&nbsp;
              <div className={`count-down-text level-${typeLabel}`}>{minutes}</div>
              &nbsp;
              <div className={`text-dots-mark text-${typeLabel}`}>:</div>&nbsp;
              <div className={`count-down-text level-${typeLabel}`}>{seconds}</div>
            </>
          )}
        </div>
      )
    }
  }

  return <Countdown date={Date.now() + timeCDMilis} renderer={renderer} />
}

export default CountDown
