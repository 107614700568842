import React, { useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import CountDown from '../../containers/countdown/CountDown'
import { countDownType, serverDateFormat } from '../../shared/constant'
import { ACCOUNT } from 'paths'
import history from '../../browserHistory'
import { getCurrentStreak } from '../../redux/actions/streakActions'
import moment from 'moment-timezone'
import Loader from '../../containers/loader/loader'

interface IDateItemProps {
  currentStreak: any
  dateAdd: number
}
const DateItem: React.FC<IDateItemProps> = (props: any) => {
  const { dateAdd, currentStreak } = props
  let valueMoment = moment(),
    isActive = false
  if (dateAdd !== 0) {
    valueMoment = moment().add(dateAdd, 'days')
  }
  if (dateAdd <= 0) {
    isActive = currentStreak?.dates?.indexOf(valueMoment.format(serverDateFormat)) > -1
  }
  return (
    <div className={'story-finish-streak-item'}>
      <img src={isActive ? '/images/icons/story-finish-active.svg' : '/images/icons/icon-streak.svg'} />
      <span className={`story-finish-streak-item-value ${isActive ? 'active' : ''}`}>{valueMoment.date()}</span>
    </div>
  )
}

interface IPremiumStoryFinishedProps {
  readAgain: any
}
const PremiumStoryFinished: React.FC<IPremiumStoryFinishedProps> = (props: any) => {
  const { credentials = {}, readAgain, currentLoading, currentStreak, getCurrentStreak } = props
  const { memberId } = credentials

  useEffect(() => {
    if (memberId && !currentLoading && !currentStreak) {
      getCurrentStreak(memberId)
    }
  }, [memberId, currentStreak, currentLoading])

  const onStreakClick = () => {
    history.push(ACCOUNT)
  }
  return (
    <div className='section-2'>
      <div className='section-story w-container'>
        <div className='w-row'>
          <div className='w-col w-col-6 content-left'>
            <span className='introduction-title'>Story Finished</span>
          </div>
          <div className='w-col w-col-6 content-right'>
            <div className='section-countdown'>
              <div className='countdown-title'>Story will change in </div>
              <CountDown type={countDownType.Day} />
            </div>
          </div>
        </div>
        <div className='w-row section-story-finished'>
          <div className='w-col-12 icon-finished'>
            <img src='/images/icons/premium-finish.svg' alt='' />
          </div>
          <div className='w-col-12 story-finished-title'>
            <span>Well done!</span>
          </div>
          {(currentStreak && (
            <div className={'m-flx m-sp-around '}>
              <DateItem dateAdd={-4} currentStreak={currentStreak} />
              <DateItem dateAdd={-3} currentStreak={currentStreak} />
              <DateItem dateAdd={-2} currentStreak={currentStreak} />
              <DateItem dateAdd={-1} currentStreak={currentStreak} />
              <DateItem dateAdd={0} currentStreak={currentStreak} />
              <DateItem dateAdd={1} currentStreak={currentStreak} />
              <DateItem dateAdd={2} currentStreak={currentStreak} />
            </div>
          )) ||
            (currentLoading && <Loader showText={false} />)}
          <div className='w-col-12 story-finished-upgrade'>
            {currentStreak && (
              <p>
                You’ve got {currentStreak?.counter} streak{currentStreak?.counter > 1 ? 's' : ''} in a row, keep it up!
              </p>
            )}
            <a className='button-4 w-inline-block m-link' onClick={readAgain}>
              Read Again
            </a>
          </div>
          <div className={'story-finish-streak-link'}>
            <p className={'m-link color-primary m-b'} onClick={onStreakClick}>
              Track your Streaks
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
  errors: state.UI.errors,
  authenticated: state.user.authenticated,
  membership: state.user.membership,
  story: state.story.currentStory,
  lastStepEnum: state.story.lastStepEnum,
  currentStreak: state.streak.current,
  currentLoading: state.streak.currentLoading,
})
const mapActionsToProps = {
  getCurrentStreak: getCurrentStreak,
}
export default connect(mapStateToProps, mapActionsToProps)(PremiumStoryFinished)
