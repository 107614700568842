import React from 'react'
import * as diff from 'diff'
import PropTypes from 'prop-types'
window.diff = diff

// const styles = {
//   added: {
//     color: "green",
//     backgroundColor: "#b5efdb"
//   },
//   removed: {
//     color: "red",
//     backgroundColor: "#fec4c0"
//   }
// };

const Diff = ({ string1 = '', string2 = '', mode = 'characters', chineseWordFontSize }): JSX.Element => {
  let groups = []

  if (mode === 'characters') groups = diff.diffChars(string1, string2)
  if (mode === 'words') groups = diff.diffWords(string1, string2)

  const mappedNodes = groups.map((group, index) => {
    const { value, added, removed } = group
    let nodeStyles
    if (added) nodeStyles = 'green-active'
    if (removed) nodeStyles = 'orange-active text-strike-out'
    return (
      <span
        style={{ fontSize: chineseWordFontSize?.newWordsExample, lineHeight: '1.5', letterSpacing: '1.5px' }}
        key={`diff${index}`}
        className={nodeStyles}
      >
        {value}
      </span>
    )
  })

  return <span>{mappedNodes}</span>
}

Diff.propTypes = {
  string1: PropTypes.string,
  string2: PropTypes.string,
  mode: PropTypes.oneOf(['characters', 'words']),
}

export default Diff
