import {
  SET_LONGEST_STREAK,
  SET_CURRENT_STREAK,
  CURRENT_STREAK_LOADING,
  LONGEST_STREAK_LOADING,
  CURRENT_MONTH_STREAK_LOADING,
  SET_MONTH_STREAK,
} from '../types'

interface IStreakState {
  current: any
  currentLoading: boolean
  longest: any
  longestLoading: boolean
  streakByMonthLoading: boolean
  streakByMonth: any
}
const initialState: IStreakState = {
  current: null,
  longest: null,
  currentLoading: false,
  longestLoading: false,
  streakByMonthLoading: false,
  streakByMonth: {},
}

export default function streakReducer(state = initialState, action: any) {
  switch (action.type) {
    case CURRENT_STREAK_LOADING:
      return {
        ...state,
        currentLoading: true,
      }
    case LONGEST_STREAK_LOADING:
      return {
        ...state,
        longestLoading: true,
      }
    case SET_CURRENT_STREAK:
      return {
        ...state,
        current: action.payload,
        currentLoading: false,
      }
    case SET_LONGEST_STREAK:
      return {
        ...state,
        longest: action.payload,
        longestLoading: false,
      }
    case CURRENT_MONTH_STREAK_LOADING:
      return {
        ...state,
        streakByMonthLoading: true,
      }
    case SET_MONTH_STREAK:
      const { month, year } = action.payload
      return {
        ...state,
        streakByMonth: { ...state.streakByMonth, [`${month}_${year}`]: action.payload },
        streakByMonthLoading: false,
      }
    default:
      return state
  }
}
