import store from 'redux/stores'
import {
  SET_ERRORS,
  SET_CURRENT_STEP,
  SET_STORY,
  SET_STORIES,
  SET_LAST_STEP,
  SET_PREVENT_REDIRECT,
  SET_SHOW_RECORDING_SUCCESS,
  SET_STORY_LOADING,
  RESET_QUIZ,
  SET_QUESTION,
  SET_QUESTION_ANSWER_LIST,
  SET_CURRENT_STREAK,
  ACCOUNT_SET_LEVEL_DONE,
} from '../types'
import { JSON_HEADER } from './config'
import { MemberStepInStory } from 'shared/constant'
// var tokenize = require("chinese-tokenizer").loadFile('./cedict_ts.u8');

const { REACT_APP_MAAYOT_API_DEV, REACT_APP_MAAYOT_API_PROD, NODE_ENV } = process.env
const MAAYOT_API = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
const headers = {
  'Content-Type': 'application/json',
}

export const getLastStep = (storyId: string, memberId: number, level: string) => {
  if (!storyId || !level || !memberId) {
    return
  }
  return fetch(`${MAAYOT_API}tracking?storyId=${storyId}&memberId=${memberId}&level=${level?.toLowerCase() || ''}`, {
    method: 'GET',
    headers,
  }).then((resData) => resData.json())
}

export const getOldWord = () => {
  return fetch(`${MAAYOT_API}words/past-new-words`, {
    method: 'GET',
    headers,
  }).then((resData) => resData.json())
}

export const getIntro = (memberId: number, level: string) => (dispatch: any) => {
  if (!level || !memberId) {
    return Promise.reject('Invalid params')
  }
  fetch(`${MAAYOT_API}prismic/getIntro?memberId=${memberId}&level=${level?.toLowerCase() || ''}`, {
    method: 'GET',
    headers,
  })
    .then((resData) => {
      return resData.json()
    })
    .then((dataStory) => {
      if (dataStory && JSON.stringify(dataStory) !== '{}') {
        return Promise.all([dataStory, getLastStep(dataStory.id, memberId, level), getOldWord()])
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: 'Story not found',
        })
        return Promise.reject("'Story not found',")
      }
    })
    .then((res) => {
      const [dataStory, lastStep, oldWordRes] = res
      dataStory.storyContent = dataStory.arContentWithTags
      dataStory.storyIntroduction = dataStory.storyIntroduction.replaceAll(/\s/g, ' ')
      dataStory.arWordSen11 = dataStory.arSentencesWord11.map((word) => word.word)
      dataStory.arWordSen12 = dataStory.arSentencesWord12.map((word) => word.word)
      dataStory.arWordSen21 = dataStory.arSentencesWord21.map((word) => word.word)
      dataStory.arWordSen22 = dataStory.arSentencesWord22.map((word) => word.word)
      dataStory.arContent = [
        ...dataStory.arContent,
        ...dataStory.arSentencesWord11,
        ...dataStory.arSentencesWord12,
        ...dataStory.arSentencesWord21,
        ...dataStory.arSentencesWord22,
      ]

      let oldWords
      if (oldWordRes?.length) {
        oldWords = []
        oldWordRes.forEach((wordObj) => {
          const { data = {} } = wordObj || {}
          const { word, level: objLevel } = data || {}
          if (objLevel?.toLowerCase() == level.toLowerCase()) {
            oldWords.push(word)
          }
        })
      }
      dispatch({
        type: SET_STORY,
        payload: { ...dataStory, oldWords },
      })
      dispatch({
        type: SET_LAST_STEP,
        payload: lastStep?.data?.[0]?.data?.step,
      })
    })
    .catch(() => {
      dispatch({
        type: SET_ERRORS,
        payload: 'Story not found',
      })
    })
}

export const getStory = (id: string, level: string, memberId: number) => (dispatch: any) => {
  if (!id || !level || !memberId) {
    return Promise.reject('Invalid params')
  }
  dispatch({
    type: SET_STORY_LOADING,
    payload: { storyLoading: true },
  })
  fetch(`${MAAYOT_API}prismic/findStory?storyId=${id}&level=${level.toLowerCase()}&memberId=${memberId}`, {
    method: 'GET',
    headers,
  })
    .then((resData) => {
      return resData.json()
    })
    .then((dataStory) => {
      if (dataStory && JSON.stringify(dataStory) !== '{}') {
        return Promise.all([dataStory, getLastStep(dataStory.id, memberId, level), getOldWord()])
      } else {
        dispatch({
          type: SET_STORY_LOADING,
          payload: { storyLoading: false },
        })
        dispatch({
          type: SET_ERRORS,
          payload: 'Story not found',
        })
        return Promise.reject("'Story not found',")
      }
    })
    .then((res) => {
      const [dataStory, lastStep, oldWordRes] = res
      dataStory.englishContent = dataStory.englishContent
      dataStory.storyContent = dataStory.arContentWithTags
      dataStory.arWordSen11 = dataStory.arSentencesWord11.map((word) => word.word)
      dataStory.arWordSen12 = dataStory.arSentencesWord12.map((word) => word.word)
      dataStory.arWordSen21 = dataStory.arSentencesWord21.map((word) => word.word)
      dataStory.arWordSen22 = dataStory.arSentencesWord22.map((word) => word.word)
      dataStory.arContent = [
        ...dataStory.arContent,
        ...dataStory.arSentencesWord11,
        ...dataStory.arSentencesWord12,
        ...dataStory.arSentencesWord21,
        ...dataStory.arSentencesWord22,
      ]
      let oldWords
      if (oldWordRes?.length) {
        oldWords = []
        oldWordRes.forEach((wordObj) => {
          const { data = {} } = wordObj || {}
          const { word, level: objLevel } = data || {}
          if (objLevel?.toLowerCase() == level.toLowerCase()) {
            oldWords.push(word)
          }
        })
      }
      dispatch({
        type: SET_STORY,
        payload: { ...dataStory, oldWords },
      })
      dispatch({
        type: SET_LAST_STEP,
        payload: lastStep?.data?.[0]?.data?.step,
      })
      dispatch({
        type: SET_STORY_LOADING,
        payload: { storyLoading: false },
      })
      return Promise.resolve({ ...dataStory, oldWords })
    })
    .catch((err) => {
      console.error(err)
      dispatch({
        type: SET_ERRORS,
        payload: 'Story not found',
      })
      dispatch({
        type: SET_STORY,
        payload: null,
      })
      dispatch({
        type: SET_STORY_LOADING,
        payload: { storyLoading: false },
      })
      return Promise.reject(err)
    })
}

export const setStories = (stories: any) => (dispatch: any) => {
  // console.log('setStories', stories)
  dispatch({
    type: SET_STORIES,
    payload: stories,
  })
}

export const getStories = (perPage: string, page: string, memberId: string, level: string) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    try {
      // fetch(`${MAAYOT_API}story-correction/closed-corrections?memberId=${memberId}`, {

      fetch(`${MAAYOT_API}prismic?par_page=${perPage}&page=${page}&level=${level.toLowerCase()}&memberId=${memberId}`, {
        method: 'GET',
        headers: JSON_HEADER,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && Object.keys(data) && Object.keys(data).length > 0) {
            // console.log('data', data)

            // dispatch({
            //   type: SET_CORRECTION_ANSWER,
            //   payload: data.answers,
            // })
            dispatch({
              type: SET_STORIES,
              payload: data,
            })
            // resolve(data.answers)
            resolve({
              data: data,
            })
          } else {
            // dispatch({
            //   type: SET_CORRECTION_ERRORS,
            //   payload: 'Error',
            // })
            reject('Error')
          }
        })
    } catch (error) {
      console.log('error')
    }
  })
}
export const setStep = (step: any) => (dispatch: any) => {
  dispatch({
    type: SET_CURRENT_STEP,
    payload: step,
  })
}

export const setShowRecordingSuccess = (show: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_SHOW_RECORDING_SUCCESS,
    payload: show,
  })
}

// translate word
export const translate = (content: string) => {
  return fetch(`${MAAYOT_API}translate`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ content }),
  })
}

export const resetStory = async (level: string, memberId: string, storyId: string) => {
  if (!level || !memberId || !storyId) {
    return
  }
  const step = MemberStepInStory.INTRODUCTION
  const content = {
    step,
    level: level?.toLowerCase() || '',
    memberId,
    storyId,
  }
  store.dispatch({
    type: ACCOUNT_SET_LEVEL_DONE,
    payload: level.toLowerCase() || '',
  })
  store.dispatch({
    type: SET_LAST_STEP,
    payload: step,
  })
  store.dispatch({
    type: SET_STORY,
    payload: {},
  })

  store.dispatch({
    type: RESET_QUIZ,
    payload: {},
  })
  store.dispatch({
    type: SET_QUESTION,
    payload: {
      question: '',
      questionContent: [],
      curAnswer: '',
    },
  })
  store.dispatch({
    type: SET_QUESTION_ANSWER_LIST,
    payload: {
      answers: [],
      cursorRefId: null,
      cursorTs: null,
    },
  })
  store.dispatch({
    type: SET_CURRENT_STREAK,
    payload: null,
  })
  try {
    const res = await fetch(`${MAAYOT_API}tracking`, {
      method: 'POST',
      headers,
      body: JSON.stringify(content),
    })
    console.log('RESET STORY REDUX AND REDIRECT TO INTRODUCTION')
    return res
  } catch (error) {
    return null
  }
}

//post Current Step
export const saveLastStep = (step: string, level: string, memberId: string, storyId: string) => (dispatch: any) => {
  if (!level || !memberId || !storyId || !step) {
    return
  }
  const content = {
    step,
    level: level?.toLowerCase() || '',
    memberId,
    storyId,
  }
  dispatch({
    type: SET_LAST_STEP,
    payload: step,
  })
  return fetch(`${MAAYOT_API}tracking`, {
    method: 'POST',
    headers,
    body: JSON.stringify(content),
  })
  // .then((resData) => resData.json())
  // .then((data) => {
  //   console.log("save step done")
  // })
  // .catch(e => {
  //   console.error(e)
  // })
}

export const setPreventRedirect = (isPrevent: true) => (dispatch: any) => {
  dispatch({
    type: SET_PREVENT_REDIRECT,
    payload: isPrevent,
  })
}

export const submitRecording =
  (id: string, memberId: string, memberName: string, uploadKey: string, level: string) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const content = {
        memberId,
        memberName,
        storyId: id,
        recordingUrl: uploadKey,
        level,
      }
      return fetch(`${MAAYOT_API}story-listening/recording`, {
        method: 'POST',
        headers,
        body: JSON.stringify(content),
      })
        .then((resData) => resData.json())
        .then((data) => {
          if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
            resolve(data)
          } else {
            dispatch({
              type: SET_ERRORS,
              payload: data.message.message,
            })
            reject(data.message.message)
          }
        })
    })
  }

export const getStoryRecording = (storyId: string, memberId: number) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    return fetch(`${MAAYOT_API}story-listening/check-exist-story-recording?storyId=${storyId}&memberId=${memberId}`, {
      method: 'GET',
      headers,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: data.message.message,
          })
          reject(data.message.message)
        }
      })
  })
}
