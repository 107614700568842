import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { SaveCharacter } from '../../redux/actions/myAccountAction'
import { connect } from 'react-redux'
import Loader from '../../containers/loader/loader'
import NotificationBox from '../../containers/form/NotificationBox'
import { characterConst } from '../../shared/constant'
import { customStyles } from './styles'

interface IChangeCharacterProps {
  isOpen: boolean
  fromCharacter: string
  toCharacter: string
  onCancel: any
  onSubmit: any
  isUpdating?: boolean
}

const ChangeCharacterPopup: React.FC<IChangeCharacterProps> = (props: any) => {
  const { isOpen, fromCharacter, toCharacter, onCancel, onSubmit, isUpdating = false } = props
  const onRequestClose = () => {
    if (!isUpdating) {
      onCancel()
    }
  }
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className={'m-popup-confirm m-flx m-flx-col'}>
        <div className={'m-h5 m-gray-1 m-al-c'}>CHANGE CHARACTER</div>
        <div className={'m-popup-body'}>
          <div className={'m-al-c'}>Are you sure to change your character from</div>
          <div className={'m-al-c'}>
            <span className={`level-${fromCharacter.toLowerCase()}`}>{fromCharacter.toUpperCase()}</span>
            <span> to </span>
            <span className={`level-${toCharacter.toLowerCase()}`}>{toCharacter.toUpperCase()}</span>
          </div>
        </div>
        {(isUpdating && <Loader showText={false} />) || (
          <div className={'popup-button m-flx m-sp-around change-level-btn '}>
            <button className={'m-b-gray-4 button-4 m-button-default'} onClick={onCancel}>
              Cancel
            </button>
            <button className={'m-b-primary-1 button-4'} onClick={() => onSubmit(toCharacter)}>
              Change Character
            </button>
          </div>
        )}
      </div>
    </ReactModal>
  )
}

interface ICharacterProps {
  bgText: string
  label: string
  checked?: boolean
  className?: string
  changeCharacter?: any
}

const selectedIcon = <i className='fa fa-check-circle color-white' />

const CharacterItem: React.FC<ICharacterProps> = (props: any) => {
  const { className, bgText, label, checked, changeCharacter } = props
  return (
    <div className={'level-item ' + className}>
      <span className={`bg-text color-white ${checked ? 'active' : ''}`}>{bgText}</span>
      <div className={'level-item-content'}>
        <span className={'m-h6 color-white'}>{label}</span>
        {checked ? (
          <div className={'level-selected'}>
            {selectedIcon}
            <span className={'color-white m-sm-text'}>Selected</span>
          </div>
        ) : (
          <div>
            <button className={`m-button level-item-btn color-white ${className}`} onClick={() => changeCharacter()}>
              Change
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

interface IPCharacterProps {
  credentials?: any
  SaveCharacter?: any
}

const Character: React.FC<IPCharacterProps> = (props: any) => {
  const { credentials, SaveCharacter } = props
  const { memberId, customFields = {}, character = characterConst.simplified } = credentials
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [nextCharacter, setNextCharacter] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [notify, setNotify] = useState({ label: '', isError: false })

  const clearNotify = () => {
    setNotify({ label: '', isError: true })
  }
  const notifySuccess = () => {
    setNotify({ label: 'Your submission has been received!', isError: false })
  }
  useEffect(() => {
    // CheckAuthentication();
  }, [])

  const changeCharacter = (character) => {
    setNextCharacter(character)
    setIsOpenModal(true)
    clearNotify()
  }
  const handleUpdateCharacter = (updateCharacter) => {
    const _character = customFields.character || character
    if (updateCharacter !== _character && !isUpdating) {
      setIsUpdating(true)
      SaveCharacter(updateCharacter, memberId)
        .then(() => {
          notifySuccess()
          setIsUpdating(false)
          setIsOpenModal(false)
        })
        .catch((e) => {
          setNotify({ label: e || 'Error when update character', isError: true })
          setIsUpdating(false)
          setIsOpenModal(false)
        })
    }
  }
  const _character: string = customFields.character || character || ''
  return (
    <>
      <div className='level-right m-b-white'>
        <h4 className={'m-h4 m-gray-1'}>Character Preference</h4>
        <NotificationBox clearNotify={clearNotify} {...notify} />
        <div className={'level-session'}>
          <div>
            <CharacterItem
              bgText={'简体'}
              label={'Simplified'}
              checked={_character === characterConst.simplified}
              className={'m-b-primary-1'}
              changeCharacter={() => changeCharacter(characterConst.simplified)}
            />
          </div>
          <div>
            <CharacterItem
              bgText={'繁體'}
              label={'Traditional'}
              checked={_character === characterConst.traditional}
              className={'m-b-primary-2'}
              changeCharacter={() => changeCharacter(characterConst.traditional)}
            />
          </div>
        </div>
      </div>
      <ChangeCharacterPopup
        fromCharacter={_character}
        toCharacter={nextCharacter}
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onSubmit={handleUpdateCharacter}
        isUpdating={isUpdating}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
})
const mapActionsToProps = {
  SaveCharacter,
}
export default connect(mapStateToProps, mapActionsToProps)(Character)
