import React from 'react'

const Tooltip = ({ text, children, disabled }) => {
  return (
    <div className='tooltip-container'>
      <div className={`tooltip ${disabled ? 'disabled' : ''}`}>
        {children}
        <span className='tooltip-text'>{text}</span>
      </div>
    </div>
  )
}

export default Tooltip
