import React from 'react'
import { Word } from 'containers/story'

interface IAnswerItemDetailProps {
  AnswerItemDetails: any
  showPinYin: any
  story: any
  handleClickWord: any
  activeIndex: any
  iterator: any
  chineseWordFontSize: any
}

const AnswerItemDetail: React.FC<IAnswerItemDetailProps> = (props: any) => {
  const { AnswerItemDetails, showPinYin, story, handleClickWord, activeIndex, iterator, chineseWordFontSize } = props

  return (
    <React.Fragment>
      {AnswerItemDetails?.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
        return (
          <React.Fragment key={index}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`${iterator}${index}`}
                isActive={`${iterator}${index}` === activeIndex}
                text={stripedWord}
                isNewWord={stripedWord === story?.newWord1 || stripedWord === story?.newWord2}
                isOldWord={isOldWord}
                onClick={handleClickWord}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
                chineseWordFontSize={chineseWordFontSize?.newWordsExample}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}
export default React.memo(AnswerItemDetail)
