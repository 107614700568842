import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { registerWithSkritter } from 'redux/actions/userActions'
import { ACCOUNT_SET_SKRITTER } from 'redux/types'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const SkitterCallbackComponent = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  React.useEffect(() => {
    const temp: any = {
      skritter_token: query.get('access_token'),
      state: query.get('state'),
      user_id: query.get('user_id'),
      token_type: query.get('token_type'),
      expires_in: query.get('expires_in'),
      refresh_token: query.get('refresh_token'),
    }
    if (temp.state) {
      temp.state = JSON.parse(decodeURIComponent(temp.state))
    }
    const cTimeSeconds = Math.floor(Date.now() / 1000)
    const newEXP = parseInt(temp.expires_in) + cTimeSeconds
    temp.expires_in = newEXP
    // console.log("Current ISO", new Date().toISOString());
    // console.log("EXP ISO", new Date(newEXP * 1000).toISOString());

    const payload = {
      memberId: temp.state.memberId,
      skritter_token: temp.skritter_token,
      refresh_token: temp.refresh_token,
      expires_in: newEXP.toString(),
      user_id: temp.user_id,
      token_type: temp.token_type,
    }
    registerWithSkritter(payload).finally(() => {
      dispatch({ type: ACCOUNT_SET_SKRITTER, payload: { token: temp.skritter_token, skritter: payload } })
      localStorage.setItem('skritterData', JSON.stringify(temp))

      window.close()
    })
  }, [])
  return null
}

export default SkitterCallbackComponent
