import React from 'react'
import Word from './Word'
interface ILeftStoryProps {
  story?: any
  handleClickWord?: any
  activeIndex?: any
  showPinYin?: boolean
  chineseWordFontSize?: any
}

const LeftStory: React.FC<ILeftStoryProps> = (props: any) => {
  const { handleClickWord, story, activeIndex, showPinYin, chineseWordFontSize } = props

  return (
    <div className='w-col w-col-7 content-left'>
      <div className='story-content story-event-select'>
        <>
          {story.storyContent.map((wordObj, index) => {
            const isOldWord = story?.oldWords?.includes(wordObj.word)
            const isNewWord = story?.newWord1 === wordObj.word || story?.newWord2 === wordObj.word
            const isIncludePTag =
              wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
            const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
            return (
              <React.Fragment key={index}>
                {isIncludePTag && index ? <br /> : ''}
                {stripedWord.length ? (
                  <Word
                    key={index}
                    index={`S${index}`}
                    isActive={`S${index}` === activeIndex}
                    text={stripedWord}
                    isNewWord={isNewWord}
                    isOldWord={!isNewWord && isOldWord}
                    onClick={handleClickWord}
                    pinYinText={showPinYin ? wordObj?.pinyin : null}
                    chineseWordFontSize={chineseWordFontSize?.storyContent}
                  />
                ) : (
                  ''
                )}
              </React.Fragment>
            )
          })}
        </>
      </div>
    </div>
  )
}
export { Word }
export default LeftStory
