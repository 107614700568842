import React, { useMemo, useState } from 'react'

const eyeIcon = <i className='fa fa-eye' />
const eyeSlashIcon = <i className='fa fa-eye-slash' />

interface IFormItemProps {
  label: string
  value?: string
  type?: string
  isEdit?: boolean
  showButton?: boolean
  onChange?: any
  onEditMode?: any
  onSave?: any
  isSubmitting?: boolean
}
const FormInput = React.forwardRef<HTMLInputElement, IFormItemProps>((props: any, ref: any) => {
  const { label, value, type, onChange, onEditMode, isEdit, showButton = true, onSave, isSubmitting = false } = props

  const [inputType, setInputType] = useState(type)
  const [isError, setIsError] = useState(false)

  const handleSave = () => {
    onSave()
  }

  const onInputChange = (e) => {
    const value = e?.target?.value || ''
    if (value === '') {
      setIsError(true)
    } else {
      setIsError(false)
    }
    onChange(e)
  }

  const icon = useMemo(() => {
    return isEdit ? (inputType == 'password' && eyeIcon) || eyeSlashIcon : null
  }, [isEdit, inputType])

  const onIconClick = (type) => {
    if (type === 'password') {
      setInputType(inputType === 'password' ? 'text' : 'password')
    }
  }
  return (
    <div className={'account-form'}>
      <div className={'account-form-item'}>
        <div className={'item-title m-gray-1'}>{label}</div>
        <div className={'item-input'}>
          {isEdit ? (
            <>
              <input
                value={value}
                type={inputType}
                onChange={onInputChange}
                ref={ref}
                className={`${isError ? 'input-error' : ''} ${showButton || icon ? 'has-right' : ''}`}
              />
              {(icon && (
                <div className={'item-input-btn'} onClick={() => onIconClick(type)}>
                  {(!showButton && icon) || null}
                </div>
              )) ||
                null}
              {(showButton && !isError && (
                <button
                  className={`m-h6 ${
                    !isSubmitting ? 'color-primary m-b' : 'm-gray-2 m-cursor-default'
                  } m-bg-none item-input-btn`}
                  onClick={() => handleSave()}
                >
                  {isSubmitting ? 'Submitting...' : 'Save'}
                </button>
              )) ||
                null}
            </>
          ) : (
            <>
              <span className={'item-input-view m-b'}>{value}</span>
              {(showButton && (
                <button className={'m-h6 color-primary m-bg-none m-b'} onClick={() => onEditMode(true)}>
                  edit
                </button>
              )) ||
                null}
            </>
          )}
        </div>
      </div>
    </div>
  )
})
export default FormInput
