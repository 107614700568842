import React from 'react'
import { characterConst, converterType } from 'shared/constant'
import Loader from '../../containers/loader/loader'
import AnswerItemDetails from './AnswerItemDetails'

interface IAnswerListProps {
  answers?: any
  hasMore: boolean
  moreClick: any
  answerLoading: boolean
  handleClickWord: any
  activeIndex: any
  story: any
  chineseWordFontSize: any
}

const AnswerList: React.FC<IAnswerListProps> = (props: any) => {
  const { answers, hasMore, moreClick, answerLoading, activeIndex, handleClickWord, story, chineseWordFontSize } = props
  return (
    <React.Fragment>
      <div className='section-question-answer'>
        <h4 className={'m-h4 m-gray-1'}>Your Answers</h4>
        {(answers?.length !== 0 && (
          <React.Fragment>
            <div className={'m-answer-list'}>
              {answers?.length !== 0 &&
                answers?.map((obj, iterator) => {
                  return (
                    <div className={'m-answer-item'} key={obj?.id}>
                      <div className={'m-answer-name m-gray-2 m-sm-text'}>{obj.name}</div>
                      <div className={'m-answer-detail'}>
                        {obj?.answer?.split('\n').map((item, key) => (
                          <React.Fragment key={key}>
                            {
                              <AnswerItemDetails
                                AnswerItemDetails={obj.answerContent}
                                showPinYin={false}
                                story={story}
                                activeIndex={activeIndex}
                                handleClickWord={handleClickWord}
                                iterator={iterator}
                                chineseWordFontSize={chineseWordFontSize}
                              />
                            }
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )
                })}
            </div>

            {/* <div className='m-answer-item correct-by-teacher'>
              <div className='m-answer-name color-primary m-sm-text '> Corrected By Teacher </div>
              <div className='teacher-answer  pt-3'>
                <span>
                  {' '}
                  在我的国 <span className='green-active'> 家网 </span> 上定外卖的价格
                  <span className='orange-active'>和服</span>务费都很贵务费都很贵务费
                  <span className='orange-active'>都很</span>贵。{' '}
                </span>
              </div>
            </div> */}

            {!answerLoading && hasMore && (
              <div className={'m-link m-b color-primary'} onClick={moreClick}>
                Show more answer
              </div>
            )}
            {answerLoading && <Loader showText={false} />}
          </React.Fragment>
        )) || <Loader />}
      </div>
    </React.Fragment>
  )
}

export default React.memo(AnswerList)
