import { useEffect, useState } from 'react'

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState({
    data: '',
    type: '',
    file: '',
  })
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [isRecordingReset, setIsRecordingReset] = useState(false)

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error)
      }
      return
    }
    // Manage recorder state.
    if (isRecording) {
      recorder?.start()
    } else {
      recorder?.stop()
    }
    // Obtain the audio when ready.
    const handleData = (e) => {
      const d = new Date()
      const file = new File([e.data], `${d.valueOf()}.mp3`, { type: 'audio/mpeg' })
      const tracks = recorder?.stream?.getTracks()
      tracks?.forEach((track) => {
        track.stop()
        return track
      })
      if (isRecordingReset) {
        setAudioURL({
          data: '',
          type: '',
          file: '',
        })
      } else {
        setAudioURL({
          data: URL.createObjectURL(e.data),
          type: 'audio/mpeg',
          file,
        })
      }
      setRecorder(null)
    }
    recorder.addEventListener('dataavailable', handleData)
    return () => recorder.removeEventListener('dataavailable', handleData)
  }, [recorder, isRecording])
  const startRecording = () => {
    setIsRecording(true)
  }
  const stopRecording = (recordingReset) => {
    setIsRecordingReset(recordingReset)
    setIsRecording(false)
  }
  return { audioURL, isRecording, startRecording, stopRecording }
}
async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  return new MediaRecorder(stream)
}
export default useRecorder
