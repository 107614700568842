import React from 'react'
export const NavStatusConst = {
  finished: 1,
  active: 2,
  notFinish: 3,
}
interface INavigationItemProps {
  status: number
  step?: number
  text: string
}

interface INavigationProps {
  data: Array<any>
  activeStep: any
}

const SelectedIcon = <i className='fa fa-check color-primary' />
const Separate = () => {
  return <div className={'m-nav-separate m-b-gray-3'} />
}
const NavigationItem: React.FC<INavigationItemProps> = (props: any) => {
  const { status = 2, step, text } = props
  let dot
  switch (status) {
    case NavStatusConst.finished:
      dot = <div className={'m-nav-default-dot m-b-gray-3'}>{SelectedIcon}</div>
      break
    case NavStatusConst.active:
      dot = <div className={'m-nav-default-dot m-b-primary color-white'}>{step}</div>
      break
    default:
      dot = <div className={'m-nav-default-dot m-b-gray-3 m-gray-2'}>{step}</div>
      break
  }
  return (
    <>
      <div className={'m-nav-default m-al-c color-primary m-b'}>
        {dot}
        {text}
      </div>
    </>
  )
}
const Navigation: React.FC<INavigationProps> = (props: any) => {
  const { data = [], activeStep } = props
  return (
    <div className={`m-nav-container steps-progressbar`}>
      <div className={`m-nav m-center active-${activeStep}`}>
        {data.map((obj, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Separate />}
              <NavigationItem status={obj.status} step={obj.step} text={obj.text} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default Navigation
