import {
  SET_ERRORS,
  SET_CURRENT_STEP,
  SET_STORY,
  SET_STORIES,
  SET_LAST_STEP,
  SET_PREVENT_REDIRECT,
  SET_SHOW_RECORDING_SUCCESS,
  SET_CORRECTION_QUESTION,
  SET_CORRECTION_ERRORS,
} from '../types'
import { JSON_HEADER } from './config'
// var tokenize = require("chinese-tokenizer").loadFile('./cedict_ts.u8');

const { REACT_APP_MAAYOT_API_DEV, REACT_APP_MAAYOT_API_PROD, NODE_ENV } = process.env
const MAAYOT_API = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
const headers = {
  'Content-Type': 'application/json',
}
declare const MemberStack: any
export const getLastStep = (storyId: string, memberId: number, level: string) => {
  if (!storyId || !level || !memberId) {
    return
  }
  return fetch(`${MAAYOT_API}tracking?storyId=${storyId}&memberId=${memberId}&level=${level?.toLowerCase() || ''}`, {
    method: 'GET',
    headers,
  }).then((resData) => resData.json())
}

export const getOldWord = () => {
  return fetch(`${MAAYOT_API}words/past-new-words`, {
    method: 'GET',
    headers,
  }).then((resData) => resData.json())
}

export const getStory = (id: string, level: string, memberId: number) => (dispatch: any) => {
  if (!id || !level || !memberId) {
    return Promise.reject('Invalid params')
  }

  fetch(`${MAAYOT_API}prismic/findStory?storyId=${id}&level=${level.toLowerCase()}&memberId=${memberId}`, {
    method: 'GET',
    headers,
  })
    .then((resData) => {
      return resData.json()
    })
    .then((dataStory) => {
      if (dataStory && JSON.stringify(dataStory) !== '{}') {
        return Promise.all([dataStory, getLastStep(dataStory.id, memberId, level), getOldWord()])
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: 'Story not found',
        })
        return Promise.reject("'Story not found',")
      }
    })
    .then((res) => {
      const [dataStory, lastStep, oldWordRes] = res
      dataStory.storyContent = dataStory.arContentWithTags
      dataStory.arWordSen11 = dataStory.arSentencesWord11.map((word) => word.word)
      dataStory.arWordSen12 = dataStory.arSentencesWord12.map((word) => word.word)
      dataStory.arWordSen21 = dataStory.arSentencesWord21.map((word) => word.word)
      dataStory.arWordSen22 = dataStory.arSentencesWord22.map((word) => word.word)
      dataStory.arContent = [
        ...dataStory.arContent,
        ...dataStory.arSentencesWord11,
        ...dataStory.arSentencesWord12,
        ...dataStory.arSentencesWord21,
        ...dataStory.arSentencesWord22,
      ]
      let oldWords
      if (oldWordRes?.length) {
        oldWords = []
        oldWordRes.forEach((wordObj) => {
          const { data = {} } = wordObj || {}
          const { word, level: objLevel } = data || {}
          if (objLevel?.toLowerCase() == level.toLowerCase()) {
            oldWords.push(word)
          }
        })
      }
      dispatch({
        type: SET_STORY,
        payload: { ...dataStory, oldWords },
      })
      dispatch({
        type: SET_LAST_STEP,
        payload: lastStep?.data?.[0]?.data?.step,
      })
      return Promise.resolve({ ...dataStory, oldWords })
    })
    .catch((err) => {
      console.error(err)
      dispatch({
        type: SET_ERRORS,
        payload: 'Story not found',
      })
      dispatch({
        type: SET_STORY,
        payload: null,
      })
      return Promise.reject(err)
    })
}

export const setStories = (stories: any) => (dispatch: any) => {
  // console.log('setStories', stories)
  dispatch({
    type: SET_STORIES,
    payload: stories,
  })
}
export const getQuestion = (id: string, level: string, memberId: string) => (dispatch: any) => {
  return new Promise<void>((resolve, reject) => {
    fetch(`${MAAYOT_API}question/?storyId=${id}&level=${level?.toLowerCase() || ''}&memberId=${memberId}`, {
      method: 'GET',
      headers: JSON_HEADER,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: SET_CORRECTION_QUESTION,
            payload: {
              question: data.question,
            },
          })
          resolve()
        } else {
          dispatch({
            type: SET_CORRECTION_ERRORS,
            payload: 'Error',
          })
          reject()
        }
      })
  })
}

export const getStories = (perPage: string, page: string, memberId: string, level: string) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    try {
      // fetch(`${MAAYOT_API}story-correction/closed-corrections?memberId=${memberId}`, {

      fetch(
        `${MAAYOT_API}stories/past-stories?par_page=${perPage}&page=${page}&level=${level.toLowerCase()}&memberId=${memberId}`,
        {
          method: 'GET',
          headers: {
            ...JSON_HEADER,
            authorization: `Bearer ${MemberStack.getToken()}`,
          },
        },
      )
        .then((res) => res.json())
        .then((data) => {
          if (data && Object.keys(data) && Object.keys(data).length > 0) {
            // console.log('data', data)

            // dispatch({
            //   type: SET_CORRECTION_ANSWER,
            //   payload: data.answers,
            // })
            dispatch({
              type: SET_STORIES,
              payload: data,
            })
            // resolve(data.answers)
            resolve({
              data: data,
            })
          } else {
            // dispatch({
            //   type: SET_CORRECTION_ERRORS,
            //   payload: 'Error',
            // })
            reject('Error')
          }
        })
    } catch (error) {
      console.log('error')
    }
  })
}

export const getStoryRecording = (storyId: string, memberId: number) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    return fetch(`${MAAYOT_API}story-listening/check-exist-story-recording?storyId=${storyId}&memberId=${memberId}`, {
      method: 'GET',
      headers,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: data.message.message,
          })
          reject(data.message.message)
        }
      })
  })
}
