import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment-timezone'
import { getCurrentStreak, getLongestStreak, getStreakByMonth } from '../../redux/actions/streakActions'
import Loader from '../../containers/loader/loader'
import { serverDateFormat } from '../../shared/constant'

interface IStreaksProps {
  credentials?: any
}
const nextIcon = <i className='fa fa-chevron-right' />
const prevIcon = <i className='fa fa-chevron-left' />
const Streaks: React.FC<IStreaksProps> = (props: any) => {
  const {
    credentials,
    getLongestStreak,
    getCurrentStreak,
    longestStreak,
    currentStreak,
    currentLoading,
    longestLoading,

    getStreakByMonth,
    streakByMonthLoading,
    streakByMonth,
  } = props

  const { memberId } = credentials
  useEffect(() => {
    if (memberId && !longestLoading && !longestStreak) {
      getLongestStreak(memberId)
    }
  }, [memberId, longestStreak, longestLoading])

  useEffect(() => {
    if (memberId && !currentLoading && !currentStreak) {
      getCurrentStreak(memberId)
    }
  }, [memberId, currentStreak, currentLoading])

  const [activeStartDate, setActiveStartDate] = useState<Date>(moment().toDate())
  const [currentActive, setCurrentActive] = useState<boolean>(false)
  const [longestActive, setLongestActive] = useState<boolean>(false)
  const [currentMonthActive, setCurrentMonthActive] = useState<boolean>(true)
  const [currentMonthYear, setCurrentMonthYear] = useState<Array<string>>([
    moment().format('MM'),
    moment().format('YYYY'),
  ])

  const updateCurrentCalendar = (momentDate: moment.Moment) => {
    clearStreakByMonth()
    setCurrentMonthYear([momentDate.format('MM'), momentDate.format('YYYY')])
  }

  const handleSetActiveStartDate = (date) => {
    const activeStartDate = moment(date?.activeStartDate || '')
    setActiveStartDate(activeStartDate.toDate())
    clearCurrentStreak()
    clearLongestStreak()
    if (activeStartDate <= moment()) {
      updateCurrentCalendar(activeStartDate)
    }
    setCurrentMonthActive(true)
  }

  useEffect(() => {
    if (memberId && !streakByMonthLoading && !streakByMonth?.[`${currentMonthYear[0]}_${currentMonthYear[1]}`]) {
      getStreakByMonth(memberId, currentMonthYear[0], currentMonthYear[1])
    }
  }, [memberId, streakByMonthLoading, currentMonthYear])

  const startLongest = useMemo(() => {
    if (longestStreak?.fromDate) {
      return moment(longestStreak.fromDate, serverDateFormat)
    }
  }, [longestStreak])

  const currentList = useMemo(() => {
    if (currentStreak?.dates?.length) {
      return currentStreak?.dates.map((date) => moment(date, serverDateFormat).format(serverDateFormat))
    }
  }, [currentStreak])

  const currentMonthlyList = useMemo(() => {
    const key = `${currentMonthYear[0]}_${currentMonthYear[1]}`
    if (streakByMonth?.[key]?.dates?.length) {
      return streakByMonth[key]?.dates.map((date) => moment(date, serverDateFormat).format(serverDateFormat))
    }
  }, [streakByMonth, currentMonthYear])

  const endLongest = useMemo(() => {
    if (longestStreak?.fromDate) {
      return moment(longestStreak.toDate, serverDateFormat)
    }
  }, [longestStreak])

  const clearCurrentStreak = () => {
    setCurrentActive(false)
  }
  const clearLongestStreak = () => {
    setLongestActive(false)
  }
  const clearStreakByMonth = () => {
    setCurrentMonthActive(false)
  }
  const jumpToCurrentStreak = () => {
    if (currentList?.length && !currentActive) {
      const startCurrent = moment(currentList[0], serverDateFormat)
      setCurrentActive(true)
      setActiveStartDate(startCurrent.toDate())
      clearLongestStreak()
      updateCurrentCalendar(startCurrent)
    } else {
      clearCurrentStreak()
      setCurrentMonthActive(true)
    }
  }
  const jumpToLongestStreaks = () => {
    if (startLongest && !longestActive) {
      setLongestActive(true)
      setActiveStartDate(startLongest.toDate())
      clearCurrentStreak()
      updateCurrentCalendar(startLongest)
    } else {
      clearLongestStreak()
      setCurrentMonthActive(true)
    }
  }
  return (
    <>
      <div className='account-right m-b-white m-streak'>
        <div className={'m-streak-info m-flx m-sp-bw'}>
          <div className={`m-streaks-current m-link ${currentActive ? 'active' : ''}`} onClick={jumpToCurrentStreak}>
            <p>Current Streak</p>
            <div className={'m-flx m-sp-bw m-al-e'}>
              {(currentLoading && <Loader showText={false} />) || (
                <>
                  <div>
                    <span className={'m-streak-count'}>{currentStreak?.counter || `...`}</span>
                    <span className={'m-streak-label'}>Day{currentStreak?.counter > 1 ? 's' : ''}</span>
                  </div>
                  <span className={'m-streak-link'}>
                    show <i className='fa fa-chevron-right' />
                  </span>
                </>
              )}
            </div>
          </div>
          <div className={`m-streaks-longest m-link ${longestActive ? 'active' : ''}`} onClick={jumpToLongestStreaks}>
            <p>Longest Streak</p>
            <div className={'m-flx m-sp-bw m-al-e'}>
              {(longestLoading && <Loader showText={false} />) || (
                <>
                  <div>
                    <span className={'m-streak-count'}>{longestStreak?.counter || `...`}</span>
                    <span className={'m-streak-label'}>Day{longestStreak?.counter > 1 ? 's' : ''}</span>
                  </div>
                  <span className={'m-streak-link'}>
                    show <i className='fa fa-chevron-right' />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <Calendar
            prev2Label={null}
            next2Label={null}
            nextLabel={nextIcon}
            prevLabel={prevIcon}
            activeStartDate={activeStartDate}
            onActiveStartDateChange={handleSetActiveStartDate}
            tileClassName={({ date }) => {
              const momentDate = moment(date)
              if (
                (longestActive &&
                  startLongest &&
                  endLongest &&
                  momentDate >= startLongest &&
                  momentDate <= endLongest) ||
                (currentActive && currentList?.indexOf(momentDate.format(serverDateFormat)) >= 0) ||
                (currentMonthActive && currentMonthlyList?.indexOf(momentDate.format(serverDateFormat)) >= 0)
              ) {
                return 'date-title-highlight'
              }
            }}
            value={new Date()}
          />
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
  membership: state.user.membership,
  longestStreak: state.streak.longest,
  currentStreak: state.streak.current,
  currentLoading: state.streak.currentLoading,
  longestLoading: state.streak.longestLoading,
  streakByMonthLoading: state.streak.streakByMonthLoading,
  streakByMonth: state.streak.streakByMonth,
})
const mapActionsToProps = {
  getLongestStreak: getLongestStreak,
  getCurrentStreak: getCurrentStreak,
  getStreakByMonth: getStreakByMonth,
}
export default connect(mapStateToProps, mapActionsToProps)(Streaks)
