import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  getStory,
  getStoryRecording,
  setStep,
  saveLastStep,
  setPreventRedirect,
  setShowRecordingSuccess,
  translate,
} from '../../redux/actions/storyAction'
import { converterType, membershipNameType, MemberStepInStory, storyStep, wordType } from '../../shared/constant'
import StoryContainer from '../../containers/story/index'
import NavigationFooter from '../../containers/navigation-footer/NavigationFooter'
import { INTRODUCTION, QUIZ } from 'paths'
import Loader from '../../containers/loader/loader'
import history from '../../browserHistory'

const eyeIcon = <i className='fa fa-eye' />
const eyeSlashIcon = <i className='fa fa-eye-slash' />
const searchplusIcon = <i className='fa fa-search-plus' />

import { characterConst } from '../../shared/constant'
import StoryIntro from 'containers/storyIntro'
import Translate from 'containers/popup/Translate'
import StoryTitle from './StoryTitle'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'
import { getQuiz, getGeneralResult } from '../../redux/actions/quizAction'
import WithRedirect from 'containers/WithRedirect'

// const FONT_SIZE_LIST = [
//   { label: '18px', value: '18px', pinyinValue: '12px' },
//   { label: '20px', value: '20px', pinyinValue: '14px' },
//   { label: '22px', value: '22px', pinyinValue: '16px' },
//   { label: '24px', value: '24px', pinyinValue: '18px' },
// ]

const Story: React.FC = (props: any) => {
  const [showPinYin, setShowPinYin] = useState(false)
  const [allowRecording, setAllowRecording] = useState(false)
  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const [isOpen, setOpen] = useState(false)
  const popupRef = useRef<HTMLInputElement>(null)
  const { storyLoading } = props
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.story])

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const newWord1 =
      globalThis.characterPreference === characterConst.traditional
        ? converterType.converterTraditional(props.story?.newWord1)
        : converterType.converterSimplified(props.story?.newWord1)
    const newWord2 =
      globalThis.characterPreference === characterConst.traditional
        ? converterType.converterTraditional(props.story?.newWord2)
        : converterType.converterSimplified(props.story?.newWord2)
    const isOldWord = props?.story?.oldWords?.includes(word)

    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    const allDataOp = [...props?.story?.arContent, ...props?.story?.storyTitleContent]

    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = allDataOp.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (props?.user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], props?.user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  const { saveLastStep, storyId, lastStep, lastStepEnum, setPreventRedirect, isGetData, showRecordingSuccess } = props

  const { customFields, memberId } = props.credentials
  const level = customFields.level || props.credentials?.level
  const firstName = props.credentials.customFields?.['first-name'] || props.credentials.metaData?.firstName

  useEffect(() => {
    if (JSON.stringify(props.story) === '{}') {
      props.getStory(storyId, level, memberId)
      if (!props?.quizDetail?.quiz) {
        props.getQuiz(storyId, level, memberId).then((result) => {
          if (result.memberAnswer) {
            props.getGeneralResult(storyId)
          }
        })
      }
    }

    getRecording()
  }, [isGetData, storyId, level, memberId])

  useEffect(() => {
    window.scrollTo(0, 0)
    props.setStep(storyStep.Step1)
    // getRecording()
  }, [])

  useEffect(() => {
    if (showRecordingSuccess) {
      setTimeout(() => props.setShowRecordingSuccess(false), 3000)
    }
  }, [showRecordingSuccess])

  useEffect(() => {
    if (
      storyId &&
      level &&
      memberId &&
      lastStep !== MemberStepInStory.STORY_AND_LISTENING &&
      (lastStepEnum || lastStepEnum === 0) &&
      lastStepEnum < storyStep.Step1
    ) {
      saveLastStep(MemberStepInStory.STORY_AND_LISTENING, level, memberId, storyId)
    }
  }, [storyId, level, memberId])

  const getRecording = () => {
    // if (props?.membershipPlan === membershipNameType.Pro && storyId && memberId) {
    if (storyId && memberId) {
      props
        .getStoryRecording(storyId, memberId)
        .then((res) => {
          setAllowRecording(!res?.record_exist)
        })
        .catch(() => setAllowRecording(false))
    }
  }

  const handleChangeChk = () => {
    setShowPinYin(!showPinYin)
  }

  const handleClose = () => {
    props.setShowRecordingSuccess(false)
  }

  const icon = useMemo(() => {
    return showPinYin ? eyeSlashIcon : eyeIcon
  }, [showPinYin])

  const audio = useMemo(() => props.story.audio, [props.story])
  const backOnClick = (e) => {
    setPreventRedirect(true)
    history.push(`${INTRODUCTION}`)
  }

  return (
    <>
      <div ms-membership='free' className='section-2'>
        <div className='section-story w-container'>
          {showRecordingSuccess ? (
            <div className='recording-submitted-popup'>
              <h6>Your recording has been submitted</h6>
            </div>
          ) : (
            ''
          )}
          <div className='w-row m-flx m-sp-bw align-center introduction-main-div'>
            <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-4  content-left pl-10'>
              <span
                style={{ fontSize: chineseWordFontSize?.storyTitle, lineHeight: '1.5', letterSpacing: '1.5px' }}
                className='introduction-title'
              >
                {props?.story?.storyTitle &&
                  (globalThis.characterPreference === characterConst.traditional ? (
                    <StoryTitle
                      storyTitleContent={props?.story?.storyTitleContent}
                      showPinYin={showPinYin}
                      story={props?.story}
                      activeIndex={activeIndex}
                      handleClickWord={handleClickWord}
                    />
                  ) : (
                    <StoryTitle
                      storyTitleContent={props?.story?.storyTitleContent}
                      showPinYin={showPinYin}
                      story={props?.story}
                      activeIndex={activeIndex}
                      handleClickWord={handleClickWord}
                    />
                  ))}
              </span>
            </div>
            {JSON.stringify(props.story) !== '{}' && (
              <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-8  pinyin-button pr-10'>
                <div className='left-sec-div'>
                  <button
                    className='button-2 bg-light-gray w-button'
                    onClick={() => {
                      handleChangeChk()
                    }}
                  >
                    {icon}
                    {showPinYin ? ` Hide PinYin` : ` Show PinYin`}
                  </button>
                </div>
              </div>
            )}
          </div>
          {JSON.stringify(props.story) !== '{}' ? (
            <>
              {(props.errors === 'Error' || props.errors === null) && props.story && (
                <>
                  <div className='w-nav story-nav-content'>
                    {/* <a className="active">简体字</a>
              <a>繁体字。</a> */}
                  </div>
                  <div className='w-row section-story-content'>
                    <StoryIntro
                      story={props.story}
                      showPinYin={showPinYin}
                      handleClickWord={handleClickWord}
                      activeIndex={activeIndex}
                      chineseWordFontSize={chineseWordFontSize}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <React.Fragment>
              {storyLoading ? (
                <Loader />
              ) : (
                <div className='item-title' style={{ textAlign: 'center' }}>
                  No translation is found
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <>
        <NavigationFooter
          audio={audio}
          backOnclick={backOnClick}
          nextPath={`${QUIZ}/${storyId}`}
          nextText={'Next Step'}
          allowRecording={allowRecording}
        />
      </>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  credentials: state.user.credentials,
  membershipPlan: state.user.membership.name,
  membership: state.user.membership,
  story: state.story.currentStory,
  storyLoading: state.story.storyLoading,
  errors: state.UI.errors,
  storyId: ownProps.match.params.id,
  lastStep: state.story.lastStep,
  lastStepEnum: state.story.lastStepEnum,
  showRecordingSuccess: state.story.showRecordingSuccess,
  isGetData:
    (state.user.credentials.level.length > 0 || state.user.credentials.customFields?.level.length > 0) &&
    !state.story.errors &&
    (JSON.stringify(state.story.currentStory) === '{}' || state.story.currentStory.id !== ownProps.match.params.id),
  quizDetail: state.quiz.quizDetail,
})

const mapActionsToProps = {
  getStory,
  getStoryRecording,
  setStep,
  saveLastStep,
  setPreventRedirect,
  setShowRecordingSuccess,
  getQuiz,
  getGeneralResult,
}
export default connect(mapStateToProps, mapActionsToProps)(WithRedirect(Story))
