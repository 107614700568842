import React from 'react'

interface ILoaderProps {
  showText?: boolean
}
const Loader: React.FC<ILoaderProps> = (props: any) => {
  const { showText = true } = props
  return (
    <div className={`m-center m-loading ${!showText ? 'no-text' : ''}`}>
      <div className='m-loading-ellipsis'>
        <div />
        <div />
        <div />
        <div />
      </div>
      {showText && <span className={'m-loading-text'}>Loading</span>}
    </div>
  )
}

export default Loader
