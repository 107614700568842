import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { getWordsList } from 'redux/actions/onboardingAction'
import { SET_ALL_WORDS } from 'redux/types'
import { characterConst } from 'shared/constant'
const WritingMethod = ({ nextStep }) => {
  const dispatch = useDispatch()
  const { languageInfo, wordList_loader }: any = useSelector<any>(({ onboarding }) => onboarding)
  const [activeBtn, setActiveBtn] = useState(languageInfo)
  const isActivePreference = activeBtn?.preferences?.find((preference) => preference?.is_active)
  const [preferenceId, setPreferenceId] = useState(isActivePreference && isActivePreference?._id)
  const handle = {
    selectPreferences: (preference_id, preference_name) => {
      const newPreferences = activeBtn?.preferences?.map((obj) => ({
        ...obj,
        is_active: obj._id === preference_id ? true : false,
      }))
      const newLanguageInfo = { ...activeBtn, preferences: newPreferences }
      setActiveBtn(newLanguageInfo)
      setPreferenceId(preference_id)
      handle.getWordsList(preference_id, preference_name.split(' ')[0])
    },
    onNext: () => {
      nextStep(3)
    },
    getWordsList: async (preference_id, preference_name) => {
      if (preferenceId && languageInfo?.preferences?.length) {
        const response = await getWordsList({
          language_id: languageInfo?._id,
          preference_id: preference_id || preferenceId,
        })
        dispatch({
          type: SET_ALL_WORDS,
          payload: { wordList: response?.data, preferenceType: preference_name },
        })
      }
    },
  }
  useEffect(() => {
    dispatch({
      type: SET_ALL_WORDS,
      payload: { wordList: [] },
    })
    handle.getWordsList(preferenceId, characterConst.simplified)
  }, [])
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <>
      <div className='w-container'>
        <div className='choose-lunguage-main-div'>
          <div className='common-title-sec'>
            <h2
              className='screen-sub-title'
              data-aos='fade-down'
              data-aos-duration='1000'
              // data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='100'
            >
              Select a writing method
            </h2>
            <p
              className='screen-para-text'
              data-aos='fade-down'
              data-aos-duration='1000'
              data-aos-once='true'
              data-aos-delay='100'
            >
              If you are unsure, we would recommend choosing Simplified Chinese.
            </p>
          </div>
          <div
            className='method-main-div'
            data-aos='fade-down'
            data-aos-duration='1000'
            // data-aos-easing='linear'
            data-aos-once='true'
            data-aos-delay='400'
          >
            {activeBtn?.preferences?.map((preferenceInfo) => (
              <button
                onClick={() => handle.selectPreferences(preferenceInfo?._id, preferenceInfo?.preference)}
                key={preferenceInfo?._id}
                className={
                  preferenceInfo?.is_active
                    ? 'method-btn btn outline-btn-active box-shadow-cls'
                    : 'method-btn btn outline-btn-class box-shadow-cls'
                }
              >
                <label className='lung-text'>{preferenceInfo?.word}</label>
                {preferenceInfo?.preference}
              </button>
            ))}
          </div>
          <div className='text-center'>
            <button
              className='common-btn-cls green-btn-class btn'
              onClick={() => handle.onNext()}
              data-aos='fade-down'
              disabled={wordList_loader}
              data-aos-duration='1000'
              // data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='400'
              data-aos-offset='0'
            >
              {wordList_loader ? 'Loading...' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default WritingMethod
