import React, { useRef, useState, useEffect } from 'react'
import debounce from 'lodash.debounce'

interface INotificationBox {
  label?: any
  clearNotify?: any
  isError?: boolean
}
const NotificationBox: React.FC<INotificationBox> = (props: any) => {
  const { label, isError = false, clearNotify } = props
  const [viewLabel, setViewLabel] = useState(label)
  const [error, setError] = useState(isError)

  const closeNotification = useRef(
    debounce(
      () => {
        clearNotify()
      },
      error ? 10000 : 4000,
    ),
  )

  useEffect(() => {
    setViewLabel(label)
    setError(isError)
    closeNotification?.current?.()
  }, [label, isError])

  if (!viewLabel) return null

  return (
    (error && (
      <div className={'error-box'}>
        <span className={'color-white'}>{label}</span>
      </div>
    )) || (
      <div className={'success-box'}>
        <span className={'color-white'}>{label}</span>
      </div>
    )
  )
}
export default NotificationBox
