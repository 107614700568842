import React from 'react'

import LeftStory from './LeftStory'
import NewWord from './NewWord'
import Word from './Word'

interface IStoryIntroProps {
  story?: any
  showPinYin?: boolean
  handleClickWord: any
  activeIndex: any
  chineseWordFontSize?: any
}

const StoryIntro: React.FC<IStoryIntroProps> = (props: any) => {
  const { story, showPinYin, handleClickWord, activeIndex, chineseWordFontSize } = props

  return (
    <>
      <LeftStory
        story={story}
        handleClickWord={handleClickWord}
        activeIndex={activeIndex}
        showPinYin={showPinYin}
        chineseWordFontSize={chineseWordFontSize}
      />
      <NewWord
        story={story}
        handleClickWord={handleClickWord}
        activeIndex={activeIndex}
        chineseWordFontSize={chineseWordFontSize}
      />
    </>
  )
}
export { Word }
export default StoryIntro
