import React from 'react'
import { Word } from 'containers/story'

interface ICorrectionQuestionProps {
  questionDetail?: any
  showPinYin: any
  handleClickWord: any
  activeIndex: any
  story: any
  chineseWordFontSize: any
}

const CorrectionQuestion: React.FC<ICorrectionQuestionProps> = (props: any) => {
  const { questionDetail, showPinYin, handleClickWord, activeIndex, story, chineseWordFontSize } = props

  return (
    <>
      {questionDetail.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isNewWord = story?.newWord1 === wordObj.word || story?.newWord2 === wordObj.word
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')

        return (
          <React.Fragment key={index}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`QU${index}`}
                isActive={`QU${index}` === activeIndex}
                text={stripedWord}
                isNewWord={isNewWord}
                isOldWord={!isNewWord && isOldWord}
                onClick={(e) => {
                  handleClickWord(e, 0)
                }}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
                chineseWordFontSize={chineseWordFontSize?.storyContent}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default React.memo(CorrectionQuestion)
