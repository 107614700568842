import React from 'react'

const IntermediateStar: React.FC = () => {
  return (
    <>
      <span className='fa fa-star fa-star-checked'></span>
      <span className='fa fa-star fa-star-checked'></span>
      <span className='fa fa-star fa-star-unchecked'></span>
    </>
  )
}

export default IntermediateStar
