import React from 'react'

const Footer: React.FC = (props: any) => {
  return (
    <div className='section-4 bg-primary-3 maayot-footer'>
      <div className='container bottom-links'>
        <div className='footer-lower-row'>
          <div className='text-block-92 w-clearfix'>
            <a href='https://www.maayot.com/blog' className='link'>
              Blog
            </a>
            <a href='https://webflow.io/' target='_blank' className='hover-link text-white'></a>
          </div>
          <div className='text-block-92 w-clearfix'>
            <a href='https://www.maayot.com/maayot-for-companies.html' className='link'>
              Companies
            </a>
            <a href='https://webflow.io/' target='_blank' className='hover-link text-white'></a>
          </div>
          <div className='text-block-92 w-clearfix'>
            <a href='https://www.maayot.com/maayot-for-schools.html' className='link'>
              Schools
            </a>
            <a href='https://webflow.io/' target='_blank' className='hover-link text-white'></a>
          </div>
          <div className='text-block-92 w-clearfix'>
            <a href='https://www.maayot.com/privacy-policy.html' className='link'>
              Privacy
            </a>
            <a href='https://webflow.io/' target='_blank' className='hover-link text-white'></a>
          </div>
          <div className='text-block-92 w-clearfix'>
            <a href='https://www.maayot.com/contact.html' className='link'>
              Contact
            </a>
            <a href='https://webflow.io/' target='_blank' className='hover-link text-white'></a>
          </div>
        </div>
        <div className='footer-lower-row'>
          <div className='text-block-105'>
            {' '}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;maayot is part of a Hong Kong registered company with
            license #2734653
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
