import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { saveLevel } from '../../redux/actions/myAccountAction'
import { connect } from 'react-redux'
import Loader from '../../containers/loader/loader'
import NotificationBox from '../../containers/form/NotificationBox'
import { levelConst } from '../../shared/constant'
import { customStyles } from './styles'
import { resetStory } from 'redux/actions/storyAction'
import { SET_LEVEL } from 'redux/types'
import store from 'redux/stores'
interface IChangeLevelProps {
  isOpen: boolean
  fromLevel: string
  toLevel: string
  onCancel: any
  onSubmit: any
  isUpdating?: boolean
}

const ChangeLevelPopup: React.FC<IChangeLevelProps> = (props: any) => {
  const { isOpen, fromLevel, toLevel, onCancel, onSubmit, isUpdating = false } = props
  const onRequestClose = () => {
    if (!isUpdating) {
      onCancel()
    }
  }
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className={'m-popup-confirm m-flx m-flx-col'}>
        <div className={'m-h5 m-gray-1 m-al-c'}>CHANGE LEVEL</div>
        <div className={'m-popup-body'}>
          <div className={'m-al-c'}>Are you sure to change your level from</div>
          <div className={'m-al-c'}>
            <span className={`level-${fromLevel.toLowerCase()}`}>{fromLevel.toUpperCase()}</span>
            <span> to </span>
            <span className={`level-${toLevel.toLowerCase()}`}>{toLevel.toUpperCase()}</span>
          </div>
        </div>
        {(isUpdating && <Loader showText={false} />) || (
          <div className={'popup-button m-flx m-sp-around change-level-btn '}>
            <button className={'m-b-gray-4 button-4 m-button-default'} onClick={onCancel}>
              Cancel
            </button>
            <button className={'m-b-primary-1 button-4'} onClick={() => onSubmit(toLevel)}>
              Change level
            </button>
          </div>
        )}
      </div>
    </ReactModal>
  )
}

interface ILevelProps {
  bgText: string
  label: string
  checked?: boolean
  className?: string
  changeLevel?: any
}

const selectedIcon = <i className='fa fa-check-circle color-white' />

const LevelItem: React.FC<ILevelProps> = (props: any) => {
  const { className, bgText, label, checked, changeLevel } = props
  return (
    <div className={'level-item ' + className}>
      <span className={`bg-text color-white ${checked ? 'active' : ''}`}>{bgText}</span>
      <div className={'level-item-content'}>
        <span className={'m-h6 color-white'}>{label}</span>
        {checked ? (
          <div className={'level-selected'}>
            {selectedIcon}
            <span className={'color-white m-sm-text'}>Selected</span>
          </div>
        ) : (
          <div>
            <button className={`m-button level-item-btn color-white ${className}`} onClick={() => changeLevel()}>
              Change
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

interface IPLevelProps {
  credentials?: any
  saveLevel?: any
  story?: any
}

const Level: React.FC<IPLevelProps> = (props: any) => {
  const { credentials, saveLevel, story } = props
  const { memberId, customFields = {}, level = levelConst.beginner } = credentials
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [nextLevel, setNextLevel] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [notify, setNotify] = useState({ label: '', isError: false })

  const clearNotify = () => {
    setNotify({ label: '', isError: true })
  }
  const notifySuccess = () => {
    setNotify({ label: 'Your submission has been received!', isError: false })
  }

  useEffect(() => {
    // CheckAuthentication();
  }, [])

  const changeLevel = (level) => {
    setNextLevel(level)
    setIsOpenModal(true)
    clearNotify()
  }
  const handleUpdateLevel = (updateLevel) => {
    const _level = customFields.level || level
    if (updateLevel !== _level && !isUpdating) {
      setIsUpdating(true)
      saveLevel(updateLevel, memberId)
        .then(() => {
          store.dispatch({ type: SET_LEVEL, payload: '' })
          notifySuccess()
          setIsUpdating(false)
          setIsOpenModal(false)
          store.dispatch({ type: SET_LEVEL, payload: updateLevel })
          resetStory(updateLevel, memberId, story.id).then((res) => res)
        })
        .catch((e) => {
          setNotify({ label: e || 'Error when update level', isError: true })
          setIsUpdating(false)
          setIsOpenModal(false)
        })
    }
  }
  const _level: string = customFields.level || level || ''
  return (
    <>
      <div className='level-right m-b-white'>
        <h4 className={'m-h4 m-gray-1'}>Level</h4>
        <NotificationBox clearNotify={clearNotify} {...notify} />
        <div className={'level-session'}>
          <div>
            <LevelItem
              bgText={'初级'}
              label={'Beginner'}
              checked={_level.toLowerCase() === levelConst.beginner.toLowerCase()}
              className={'m-b-primary-1'}
              changeLevel={() => changeLevel(levelConst.beginner)}
            />
          </div>
          <div>
            <LevelItem
              bgText={'中级'}
              label={'Intermediate'}
              checked={_level.toLowerCase() === levelConst.intermediate.toLowerCase()}
              className={'m-b-primary-2'}
              changeLevel={() => changeLevel(levelConst.intermediate)}
            />
          </div>
          <div>
            <LevelItem
              bgText={'高级'}
              label={'Advanced'}
              checked={_level.toLowerCase() === levelConst.advanced.toLowerCase()}
              className={'m-b-error'}
              changeLevel={() => changeLevel(levelConst.advanced)}
            />
          </div>
        </div>
      </div>
      <ChangeLevelPopup
        fromLevel={_level}
        toLevel={nextLevel}
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onSubmit={handleUpdateLevel}
        isUpdating={isUpdating}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
  story: state.story.currentStory,
})
const mapActionsToProps = {
  saveLevel,
  resetStory,
}
export default connect(mapStateToProps, mapActionsToProps)(Level)
