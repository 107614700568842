import React from 'react'
import Word from './Word'

interface IStoryTitleProps {
  storyTitleContent?: any
  showPinYin: any
  story: any
  handleClickWord: any
  activeIndex: any
  chineseWordFontSize: any
}

const StoryTitle: React.FC<IStoryTitleProps> = (props: any) => {
  const { storyTitleContent, showPinYin, story, activeIndex, handleClickWord, chineseWordFontSize } = props

  return (
    <>
      {storyTitleContent?.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isNewWord = story?.newWord1 === wordObj.word || story?.newWord2 === wordObj.word
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')

        return (
          <React.Fragment key={index}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`T${index}`}
                isActive={`T${index}` === activeIndex}
                text={stripedWord}
                isNewWord={isNewWord}
                isOldWord={!isNewWord && isOldWord}
                onClick={handleClickWord}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
                chineseWordFontSize={chineseWordFontSize?.storyContent}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default StoryTitle
