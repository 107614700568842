import { MemberStepInStory, StepMap2Enum, storyStep } from '../../shared/constant'
import {
  CLEAR_QUESTION_ANSWER_LIST,
  SET_CORRECTION_ANSWER,
  SET_CORRECTION_COUNT,
  SET_CORRECTION_DATA,
  SET_CORRECTION_ERRORS,
  SET_CORRECTION_QUESTION,
  SET_CORRECTION_STORY,
  SET_CURRENT_STEP,
  SET_LAST_STEP,
  SET_PREVENT_REDIRECT,
  SET_QUESTION,
  SET_QUESTION_ANSWER_LIST,
  SET_SHOW_RECORDING_SUCCESS,
  SET_STORIES,
  SET_STORY,
  SET_SUBMIT_QUESTION,
} from '../types'

interface ICorrectionState {
  Data: any
  correctionsMaxCount: number
  answers: any
  currentStory: any
  currentStep: any
  lastStep: any
  lastStepEnum: any
  preventRedirect: boolean
  showRecordingSuccess: boolean
  loading: boolean
  CorrectionError: string
  question: any
  questionContent: any
}

const initialState: ICorrectionState = {
  Data: [],
  correctionsMaxCount: 0,
  answers: [],
  currentStory: {},
  currentStep: storyStep.Intro,
  lastStep: null,
  lastStepEnum: null,
  preventRedirect: false,
  showRecordingSuccess: false,
  loading: false,
  CorrectionError: '',
  question: '',
  questionContent: [],
}

export default function studentCorrectionsReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CORRECTION_ANSWER:
      return {
        ...state,
        answers: action.payload,
      }
    case SET_CORRECTION_DATA:
      return {
        ...state,
        Data: [...action.payload],
      }
    case SET_CORRECTION_COUNT:
      return {
        ...state,
        correctionsMaxCount: action.payload,
      }
    case SET_CORRECTION_ERRORS:
      return {
        ...state,
        CorrectionError: action.payload,
      }

    // story reducer
    case SET_STORIES:
      return {
        ...state,
        stories: [...action.payload],
        currentStory: {},
      }
    case SET_CORRECTION_STORY:
      return {
        ...state,
        currentStory: {
          ...action.payload,
        },
      }
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      }
    case SET_LAST_STEP:
      const lastStep = action.payload || MemberStepInStory.INTRODUCTION
      return {
        ...state,
        lastStep: lastStep,
        lastStepEnum: StepMap2Enum[lastStep],
      }
    case SET_PREVENT_REDIRECT:
      return {
        ...state,
        preventRedirect: action.payload,
      }
    case SET_SHOW_RECORDING_SUCCESS:
      return {
        ...state,
        showRecordingSuccess: action.payload,
      }

    // question reducer
    case SET_QUESTION_ANSWER_LIST:
      return {
        ...state,
        answers: [...state.answers, ...action.payload.answers],
        cursorRefId: action.payload.ref || null,
        cursorTs: action.payload.ts || null,
      }
    case SET_CORRECTION_QUESTION:
      return {
        ...state,
        question: action.payload.question,
        questionContent: action.payload.questionContent,
        // curAnswer: action.payload.memberAnswer || '',
      }
    case CLEAR_QUESTION_ANSWER_LIST:
      return {
        ...state,
        answers: [],
        cursorRefId: null,
        cursorTs: null,
      }
    case SET_SUBMIT_QUESTION:
      return {
        ...state,
        curAnswer: action.payload,
      }

    default:
      return state
  }
}
