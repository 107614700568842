import moment from 'moment'
import { useEffect, useState } from 'react'
import { resetStory } from 'redux/actions/storyAction'

export const checkStoryExpiration = (story) => {
  const last_publication_seconds = story?.last_publication_date || 0
  const storyExpiration = moment(last_publication_seconds * 1000).add(24, 'h')
  const currentDateInMili = moment()

  // if (last_publication_seconds !== 0) {
  //   console.log({
  //     storyExpiration: storyExpiration.format('DD MM YYYY hh:mm:ss'),
  //     currentDateInMili: currentDateInMili.format('DD MM YYYY hh:mm:ss'),
  //     NN: 'NNN',
  //   })
  // }

  if (storyExpiration <= currentDateInMili) {
    return true
  }
  return false
}

const useCheckStory = ({ story, level, memberId, reset = true }) => {
  const [isExpired, setIsExpired] = useState(false)
  const is = checkStoryExpiration(story)
  useEffect(async () => {
    setIsExpired(is)
    if (reset && is && level && memberId) {
      const res = await resetStory(level, memberId, story.id)
    }
  }, [is, level, memberId])

  return [isExpired]
}

export default useCheckStory
