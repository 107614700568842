import { converterType } from 'shared/constant'
const { REACT_APP_SKRITTEROAUTH_CLIENTID, REACT_APP_SKRITTEROAUTH_CLIENTSECRET } = process.env

const SKRITTER_REDIRECT_CALLBACK_URL: any = window.location.origin + '/callback'
const SKRITTER_APP: any = 'maayot'
const SKRITTER_CLIENT_ID: any = REACT_APP_SKRITTEROAUTH_CLIENTID
const SKRITTER_CLIENT_SECRET: any = REACT_APP_SKRITTEROAUTH_CLIENTSECRET

export const skritterUrl = 'https://skritter.com'

export const handleSkritterLogin = (payload) => {
  const uriEncodedRedirect = encodeURIComponent(SKRITTER_REDIRECT_CALLBACK_URL)

  const state = encodeURIComponent(JSON.stringify({ ...payload }))

  const skritterConnectUrl =
    skritterUrl +
    '/oauth/connect?client_id=' +
    SKRITTER_CLIENT_ID +
    '&redirect_uri=' +
    uriEncodedRedirect +
    '&state=' +
    state

  const windowFeatures = 'popup'
  window.open(skritterConnectUrl, 'skritterConnectWindow', windowFeatures)
}

export const getSkritterToken = () => {
  let local: any = {}
  local = localStorage.getItem('skritterData')
  if (local) {
    local = JSON.parse(local)
  }
  return local
}

export const getSkritterWordPayload = (data) => {
  const getStoryString = (titleArr) => {
    let storyString = ''
    titleArr.forEach((eml) => {
      storyString += eml.word
    })
    return storyString
  }
  const { word, translate, title } = data
  const temp = {
    from: SKRITTER_APP,
    lang: 'zh',
    vocabs: [
      {
        writing: word,
        tradWriting: converterType.converterTraditional(word),
        reading: translate?.pin1yin1 || '',
        definition: translate?.english?.split('/')?.[0],
        sourceName: getStoryString(title),
        sourceUrl: 'https://story.maayot.com/stories',
      },
    ],
  }
  return temp
}

export async function refreshSkritterAuthToken(refreshToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch('https://api.skritter.com/v3/auth/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: SKRITTER_CLIENT_ID,
          client_secret: SKRITTER_CLIENT_SECRET,
        }),
      })

      const responseData = await response.json()
      return resolve(responseData)
    } catch (error) {
      return reject(error)
    }
  })
}
