import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { PersistGate } from 'redux-persist/integration/react'

import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { persistor } from './redux/stores'
import { checkAuthen } from './redux/actions/userActions'
import { checkStoryExpiration } from 'hooks/useCheckStory'
import { resetStory } from 'redux/actions/storyAction'
import { CHOOSE_LANGUAGE, INTRODUCTION } from 'paths'
import history from 'browserHistory'
import { SET_STEPPER_STEP } from 'redux/types'

const check = async (currentStory, level, memberId) => {
  const isUpdated = checkStoryExpiration(currentStory)
  if (isUpdated) {
    const res = await resetStory(level, memberId, currentStory.id)
    return isUpdated
  }
  return false
}
// call check authentication
const CheckAuthApp = () => {
  const dispatch = useDispatch()
  const { languageInfo, refUser }: any = useSelector<any>(({ onboarding }) => onboarding)
  const userState: any = useSelector<any>(({ user }) => user)

  if (window.location.pathname.includes('/onboarding')) {
    dispatch({ type: SET_STEPPER_STEP, payload: 7 })
    history.replace(
      `/learn-${languageInfo?.language?.toLowerCase()}/${
        userState?.authenticated ? 'existing' : refUser ? 'lead' : 'new'
      }`,
    )
    checkAuthen()
  }
  useEffect(() => {
    if (
      history?.location?.pathname.includes('integrations/beeminder') ||
      history?.location?.pathname.includes(CHOOSE_LANGUAGE) ||
      history?.location?.pathname.startsWith('/learn')
    ) {
      console.log('return from check')
      return
    }
    checkAuthen().then(async () => {
      console.log('checkAuthen called')
      const currentStory = store?.getState()?.story?.currentStory
      const credentials = store?.getState()?.user?.credentials
      const { memberId, customFields } = credentials
      const level = customFields?.level || credentials?.level
      const checkRes = await check(currentStory, level, memberId)
      if (checkRes) {
        history.push(INTRODUCTION)
      }
    })
  }, [])
  return <App />
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CheckAuthApp />
      {/* <App /> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
