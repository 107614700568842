import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { membershipNameType, MemberStepInStory, storyStep } from '../../shared/constant'
import StoryFinished from './StoryFinished'
import PremiumStoryFinished from './PremiumStoryFinished'
import { isEnableStreaking } from '../../utils/services'
import { saveLastStep, setStep } from '../../redux/actions/storyAction'
import { idValidAccount, isSunday } from '../../utils/utils'
import history from '../../browserHistory'
import { INTRODUCTION } from '../../paths'

const Question: React.FC = (props: any) => {
  const { setStep, credentials = {}, story, lastStepEnum, saveLastStep, membership } = props
  const { memberId, customFields } = credentials
  const level = customFields?.level || props.credentials?.level
  const { name, id } = membership || {}

  useEffect(() => {
    setStep(storyStep.Step4)
  }, [])

  useEffect(() => {
    if (story.id && level && memberId && lastStepEnum !== null && lastStepEnum < storyStep.Step4) {
      saveLastStep(MemberStepInStory.FINISH, level, memberId, story.id)
    }
  }, [story.id, level, memberId])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (props.authenticated) {
      if (idValidAccount(props.membershiableStreakp?.name, props.membershiableStreakp?.status) || isSunday()) {
      }
    }
  }, [props])
  const readAgain = () => {
    console.log(
      'MemberStepInStory.INTRODUCTION, level, memberId, story.id',
      MemberStepInStory.INTRODUCTION,
      level,
      memberId,
      story.id,
    )
    // debugger

    saveLastStep(MemberStepInStory.INTRODUCTION, level, memberId, story.id)
    history.push(INTRODUCTION)
  }

  const enableStreak = useMemo(() => isEnableStreaking(name, id), [name, id])
  return <>{enableStreak ? <PremiumStoryFinished readAgain={readAgain} /> : <StoryFinished readAgain={readAgain} />}</>
}

const mapStateToProps = (state: any, ownProps: any) => ({
  membership: state.user.membership,
  credentials: state.user.credentials,
  authenticated: state.user.authenticated,
  story: state.story.currentStory,
  lastStepEnum: state.story.lastStepEnum,
})
const mapActionsToProps = {
  setStep,
  saveLastStep,
}
export default connect(mapStateToProps, mapActionsToProps)(Question)
